import { Button, Tooltip } from '@chakra-ui/react';
import { StudioNodeData, StudioNodeType } from '@common/studio-types';
import { Field, Icon, Textarea } from '@maestro/components';
import { FeatureFlags, useFeatureFlag } from '@maestro/feature-flags';
import { dimensions } from '@maestro/styles';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { shouldShowGenerateWithAi } from '../hooks/useGenerateNodeWithAi';
import { nodeConfigs } from '../nodes';
import { GenerateImageWithAiFields } from './ai/GenerateImageWithAiFields';
import { Drawer } from './Drawer';
import { GenerateNodeWithAi } from './GenerateNodeWithAi';
import { NodeForm } from './NodeForm';

type Props = {
  isOpen: boolean;
  studioNodeData?: StudioNodeData;
  onStudioNodeDataChange: (studioNode: StudioNodeData) => void;
  onCancel: VoidFunction;
};

export const StudioDrawer: React.FC<Props> = (props) => {
  const [isAiOpen, setIsAiOpen] = useState(false);
  const title = props.studioNodeData
    ? nodeConfigs[props.studioNodeData.type]?.name
    : '';

  const isAiTemplateEpisodesEnabled = useFeatureFlag(
    FeatureFlags.AiTemplateEpisodes,
  );
  const isGenerateImagesWithAiEnabled = useFeatureFlag(
    FeatureFlags.GenerateImagesWithAi,
  );
  const toggleAi = () => setIsAiOpen(!isAiOpen);

  const showAiButton =
    props.studioNodeData &&
    shouldShowGenerateWithAi(props.studioNodeData?.type) &&
    (props.studioNodeData?.type === StudioNodeType.Image
      ? isGenerateImagesWithAiEnabled
      : true);

  useEffect(() => setIsAiOpen(false), [props.studioNodeData]);

  return (
    <Drawer
      ariaLabel="Node Settings"
      isOpen={props.isOpen && !!title}
      onCancel={props.onCancel}
      title={title}
      headerContent={
        <>
          {showAiButton && (
            <Tooltip
              label="Generate with AI"
              aria-label="Generate with AI"
              placement="bottom-end"
            >
              <Button
                variant="default"
                size="sm"
                onClick={toggleAi}
                isActive={isAiOpen}
              >
                <Icon name="edit-with-ai" size={16} />
              </Button>
            </Tooltip>
          )}
        </>
      }
    >
      {props.studioNodeData && (
        <Container>
          {isAiOpen && (
            <GenerateNodeWithAi
              studioNodeData={props.studioNodeData}
              onStudioNodeDataChange={props.onStudioNodeDataChange}
              onFinishGeneration={() => setIsAiOpen(false)}
            />
          )}
          <NodeForm
            studioNodeData={props.studioNodeData}
            onStudioNodeDataChange={props.onStudioNodeDataChange}
          />
          {isAiTemplateEpisodesEnabled && (
            <AiContainer>
              <Field label="Instructions to AI">
                <Textarea
                  placeholder="Tell AI how to generate this node"
                  value={props.studioNodeData.aiInstructions ?? ''}
                  onChange={(e) =>
                    props.onStudioNodeDataChange({
                      ...props.studioNodeData!,
                      aiInstructions: e.target.value,
                    })
                  }
                />
              </Field>
              {isGenerateImagesWithAiEnabled &&
                props?.studioNodeData?.type === StudioNodeType.Image && (
                  <GenerateImageWithAiFields
                    onStudioNodeDataChange={props.onStudioNodeDataChange}
                    studioNodeData={props.studioNodeData}
                  />
                )}
            </AiContainer>
          )}
        </Container>
      )}
    </Drawer>
  );
};

const Container = styled.div`
  padding: ${dimensions.size16};
`;

const AiContainer = styled.div`
  padding: ${dimensions.size16} ${dimensions.size0};
`;
