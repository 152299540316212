export const uniquenessOptions = [
  {
    value: 'unique',
    label: 'Unique',
  },
  {
    value: 'blend',
    label: 'Blend',
  },
  {
    value: 'exact',
    label: 'Exact',
  },
];
