import { useMutation, useQuery, useInfiniteQuery, UseMutationOptions, UseQueryOptions, UseInfiniteQueryOptions } from '@tanstack/react-query';
import { fetcher } from './../fetcher';
export type Maybe<T> = T | null | undefined;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A timestamp in ISO format */
  DateTime: string;
  JSON: Record<string, unknown>;
  /** The JavaScript Date is an integer. Type represents date and time as number of milliseconds from start of the UNIX epoch. */
  Timestamp: string;
  URL: string;
  Void: void;
};

export type AcceptDraftEpisodeInvitationError = {
  __typename?: 'AcceptDraftEpisodeInvitationError';
  code: AcceptDraftEpisodeInvitationErrorCode;
  message: Scalars['String'];
};

export enum AcceptDraftEpisodeInvitationErrorCode {
  InvalidEpisodeId = 'INVALID_EPISODE_ID',
  Unknown = 'UNKNOWN'
}

export type AcceptDraftEpisodeInvitationMutationInput = {
  episodeId: Scalars['ID'];
};

export type AcceptDraftEpisodeInvitationMutationResponse = {
  __typename?: 'AcceptDraftEpisodeInvitationMutationResponse';
  episode?: Maybe<MinifiedEpisode>;
  error?: Maybe<Array<AcceptDraftEpisodeInvitationError>>;
};

export type AppUpdateData = {
  __typename?: 'AppUpdateData';
  minimumBuildNumber: Scalars['Int'];
  updateUrl: Scalars['String'];
};

export type AuthenticateWithProviderInput = {
  /** The provider a user is authenticating with */
  provider: AuthenticationProvider;
  /** Open object for provider-specific metadata needed to authenticate a user */
  providerMetadata: ProviderMetadata;
  /** The identity token provided by the provider to authenticate this user */
  token: Scalars['String'];
};

export type AuthenticateWithProviderResponse = {
  __typename?: 'AuthenticateWithProviderResponse';
  error?: Maybe<Array<AuthenticationError>>;
  /** Boolean to indicate if a user just linked a non-device authenticator */
  isNewlyLinked?: Maybe<Scalars['Boolean']>;
  jwt?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export enum AuthenticationError {
  Unknown = 'UNKNOWN'
}

export enum AuthenticationProvider {
  Apple = 'APPLE',
  Device = 'DEVICE',
  Google = 'GOOGLE',
  Supabase = 'SUPABASE'
}

export type ButtonCta = {
  __typename?: 'ButtonCTA';
  params?: Maybe<Scalars['JSON']>;
  route: Scalars['String'];
  text: Scalars['String'];
};

export type Character = {
  __typename?: 'Character';
  avatarUrl?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  contractAddress?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  inventory?: Maybe<Array<InventoryItem>>;
  isCreated?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  tbaAddress?: Maybe<Scalars['String']>;
  tokenId?: Maybe<Scalars['String']>;
};

export type CharacterAttribute = {
  __typename?: 'CharacterAttribute';
  description: Scalars['String'];
  name: Scalars['String'];
  ref: Scalars['String'];
};

export type CharacterAttributeValue = {
  __typename?: 'CharacterAttributeValue';
  /** @deprecated Character attributes are no longer global */
  description: Scalars['String'];
  /** @deprecated Character attributes are no longer global */
  name: Scalars['String'];
  /** @deprecated Character attributes are no longer global */
  percentageChange?: Maybe<Scalars['Float']>;
  /** @deprecated Character attributes are no longer global */
  ref: Scalars['String'];
  /** @deprecated Character attributes are no longer global */
  value: Scalars['Int'];
};

export type ClearUserEpisodesInSeriesError = {
  __typename?: 'ClearUserEpisodesInSeriesError';
  code: ClearUserEpisodesInSeriesErrorCode;
  message: Scalars['String'];
};

export enum ClearUserEpisodesInSeriesErrorCode {
  Unknown = 'UNKNOWN'
}

export type ClearUserEpisodesInSeriesInput = {
  seriesId: Scalars['ID'];
};

export type ClearUserEpisodesInSeriesResponse = {
  __typename?: 'ClearUserEpisodesInSeriesResponse';
  error?: Maybe<Array<ClearUserEpisodesInSeriesError>>;
};

export enum CoinSide {
  Heads = 'HEADS',
  Tails = 'TAILS'
}

export type CoinTossInteractionInput = {
  interactionId: Scalars['String'];
  type: CoinTossInteractionType;
  userEpisodeId: Scalars['ID'];
};

export enum CoinTossInteractionType {
  /** The user is asked to confirm their choice when they lose the coin toss */
  ConfirmFail = 'CONFIRM_FAIL',
  Heads = 'HEADS',
  Tails = 'TAILS'
}

export type CoinTossResult = {
  __typename?: 'CoinTossResult';
  confirmed: Scalars['Boolean'];
  result: CoinSide;
  selectedChoice: CoinSide;
  successful: Scalars['Boolean'];
};

export type ContinueEpisodeInput = {
  userEpisodeId: Scalars['ID'];
};

export type CreateAiEpisodeJobError = {
  __typename?: 'CreateAiEpisodeJobError';
  code: CreateAiEpisodeJobErrorCode;
  message: Scalars['String'];
};

export enum CreateAiEpisodeJobErrorCode {
  InvalidInput = 'INVALID_INPUT',
  Unknown = 'UNKNOWN'
}

export type CreateAiEpisodeJobInput = {
  episodeTemplateId: Scalars['ID'];
  props: Scalars['JSON'];
};

export type CreateAiEpisodeJobResponse = {
  __typename?: 'CreateAiEpisodeJobResponse';
  error?: Maybe<Array<CreateAiEpisodeJobError>>;
  jobId: Scalars['ID'];
};

export type CreateDiscoverySectionInput = {
  name: Scalars['String'];
};

export type CreateDiscoverySectionResponse = {
  __typename?: 'CreateDiscoverySectionResponse';
  error?: Maybe<Array<DiscoveryError>>;
  section?: Maybe<DiscoverySection>;
};

export enum CreateEmulatorSessionErrorCode {
  Unknown = 'UNKNOWN'
}

export type CreateEmulatorSessionResponse = {
  __typename?: 'CreateEmulatorSessionResponse';
  error?: Maybe<Array<CreateEmulatorSessionErrorCode>>;
  session?: Maybe<EmulatorSession>;
};

export type CreateEpisodeError = DisplayableError & {
  __typename?: 'CreateEpisodeError';
  code: CreateEpisodeErrorCode;
  message: Scalars['String'];
  source?: Maybe<Scalars['String']>;
};

export enum CreateEpisodeErrorCode {
  InvalidInput = 'INVALID_INPUT',
  Unauthorized = 'UNAUTHORIZED',
  Unknown = 'UNKNOWN'
}

export type CreateEpisodeInput = {
  coverImageUrl?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  seriesId: Scalars['ID'];
  title?: InputMaybe<Scalars['String']>;
};

export type CreateEpisodeResponse = {
  __typename?: 'CreateEpisodeResponse';
  episode?: Maybe<StudioEpisode>;
  error?: Maybe<Array<CreateEpisodeError>>;
};

export enum CreateSeriesErrorCode {
  Unknown = 'UNKNOWN'
}

export type CreateSeriesInput = {
  coverImageUrl?: InputMaybe<Scalars['String']>;
  /** Creation of Series includes creating an untitled episode */
  createFirstEpisode?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  genreId?: InputMaybe<Scalars['ID']>;
  title?: InputMaybe<Scalars['String']>;
  worldId?: InputMaybe<Scalars['ID']>;
};

export type CreateSeriesResponse = {
  __typename?: 'CreateSeriesResponse';
  error?: Maybe<Array<CreateSeriesErrorCode>>;
  firstEpisodeId?: Maybe<Scalars['ID']>;
  series?: Maybe<Series>;
};

export type CreateSeriesSatelliteDataOption = {
  __typename?: 'CreateSeriesSatelliteDataOption';
  id: Scalars['String'];
  label: Scalars['String'];
};

export enum CreateSeriesSatelliteDataQueryErrorCode {
  Unknown = 'UNKNOWN'
}

export type CreateSeriesSatelliteDataResponse = {
  __typename?: 'CreateSeriesSatelliteDataResponse';
  error?: Maybe<Array<CreateSeriesSatelliteDataQueryErrorCode>>;
  genres?: Maybe<Array<CreateSeriesSatelliteDataOption>>;
  worlds?: Maybe<Array<CreateSeriesSatelliteDataOption>>;
};

export type CreateSignedUploadUrResponse = {
  __typename?: 'CreateSignedUploadUrResponse';
  error?: Maybe<Array<CreateSignedUploadUrlError>>;
  url?: Maybe<Scalars['String']>;
};

export type CreateSignedUploadUrlError = DisplayableError & {
  __typename?: 'CreateSignedUploadUrlError';
  code: CreateSignedUploadUrlErrorCode;
  message: Scalars['String'];
  source: Scalars['String'];
};

export enum CreateSignedUploadUrlErrorCode {
  ProviderError = 'PROVIDER_ERROR',
  Unknown = 'UNKNOWN'
}

export type CreateSignedUploadUrlInput = {
  fileName: Scalars['String'];
  objectType: StorageObjectType;
};

export type CreateUserEpisodeError = {
  __typename?: 'CreateUserEpisodeError';
  code: CreateUserEpisodeErrorCode;
  message: Scalars['String'];
};

export enum CreateUserEpisodeErrorCode {
  DuplicateEntry = 'DUPLICATE_ENTRY',
  EpisodeLocked = 'EPISODE_LOCKED',
  EpisodeNotFound = 'EPISODE_NOT_FOUND'
}

export type CreateUserEpisodeResponse = {
  __typename?: 'CreateUserEpisodeResponse';
  data?: Maybe<UserEpisode>;
  error?: Maybe<Array<CreateUserEpisodeError>>;
};

export type CreditActivity = {
  __typename?: 'CreditActivity';
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['ID'];
  quantity: Scalars['Int'];
  subtitle?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type: CreditActivityType;
};

export type CreditActivityInput = {
  filterType?: InputMaybe<CreditActivityType>;
};

export enum CreditActivityType {
  Expense = 'EXPENSE',
  Income = 'INCOME'
}

export enum DeleteAccountError {
  Unknown = 'UNKNOWN'
}

export type DeleteAccountResponse = {
  __typename?: 'DeleteAccountResponse';
  error?: Maybe<Array<DeleteAccountError>>;
};

export type DeleteDiscoverySectionInput = {
  id: Scalars['ID'];
};

export type DeleteDiscoverySectionResponse = {
  __typename?: 'DeleteDiscoverySectionResponse';
  error?: Maybe<Array<DiscoveryError>>;
};

export enum DeviceType {
  Android = 'ANDROID',
  Ios = 'IOS',
  Unknown = 'UNKNOWN'
}

export type Dice = {
  __typename?: 'Dice';
  id: Scalars['ID'];
  name: Scalars['String'];
  sides: Scalars['Int'];
};

export type DiceRollInteractionInput = {
  interactionId: Scalars['String'];
  userEpisodeId: Scalars['ID'];
};

export type DiceRollResult = {
  __typename?: 'DiceRollResult';
  dice: Dice;
  result: Scalars['Int'];
};

export type DiscoveryError = DisplayableError & {
  __typename?: 'DiscoveryError';
  code: DiscoveryErrorCode;
  message: Scalars['String'];
  source: Scalars['String'];
};

export enum DiscoveryErrorCode {
  InvalidInput = 'INVALID_INPUT',
  Unknown = 'UNKNOWN'
}

export type DiscoveryInput = {
  includeEmptySections?: InputMaybe<Scalars['Boolean']>;
};

export type DiscoveryResponse = {
  __typename?: 'DiscoveryResponse';
  error?: Maybe<Array<DiscoveryError>>;
  sections?: Maybe<Array<DiscoverySection>>;
};

export type DiscoverySection = {
  __typename?: 'DiscoverySection';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  payload: DiscoverySectionPayload;
  type: DiscoverySectionType;
};

export type DiscoverySectionContinuePlaying = {
  __typename?: 'DiscoverySectionContinuePlaying';
  recentEpisodes: Array<RecentEpisode>;
};

export type DiscoverySectionGenres = {
  __typename?: 'DiscoverySectionGenres';
  genres: Array<SeriesGenre>;
};

export type DiscoverySectionHeroImage = {
  __typename?: 'DiscoverySectionHeroImage';
  badge?: Maybe<Scalars['String']>;
  button: ButtonCta;
  description?: Maybe<Scalars['String']>;
  imageUrl: Scalars['String'];
  title: Scalars['String'];
};

export type DiscoverySectionMutations = {
  __typename?: 'DiscoverySectionMutations';
  createSectionSeries: CreateDiscoverySectionResponse;
  deleteSection: DeleteDiscoverySectionResponse;
  updateSectionSeries: UpdateDiscoverySectionResponse;
};


export type DiscoverySectionMutationsCreateSectionSeriesArgs = {
  input: CreateDiscoverySectionInput;
};


export type DiscoverySectionMutationsDeleteSectionArgs = {
  id: Scalars['ID'];
};


export type DiscoverySectionMutationsUpdateSectionSeriesArgs = {
  input: UpdateDiscoverySectionInput;
};

export type DiscoverySectionPayload = DiscoverySectionGenres | DiscoverySectionHeroImage | DiscoverySectionRecentEpisodes | DiscoverySectionSeries;

export type DiscoverySectionRecentEpisodes = {
  __typename?: 'DiscoverySectionRecentEpisodes';
  recentEpisodes: Array<RecentEpisode>;
};

export type DiscoverySectionSeries = {
  __typename?: 'DiscoverySectionSeries';
  onlyForKids: Scalars['Boolean'];
  series: Array<Series>;
};

export enum DiscoverySectionType {
  Genres = 'GENRES',
  HeroImage = 'HERO_IMAGE',
  RecentEpisodes = 'RECENT_EPISODES',
  Series = 'SERIES'
}

export type DisplayableError = {
  message: Scalars['String'];
  source?: Maybe<Scalars['String']>;
};

export type DraftEpisodeVersion = {
  __typename?: 'DraftEpisodeVersion';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  studioFlow: Scalars['JSON'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmulatorSession = {
  __typename?: 'EmulatorSession';
  jwt: Scalars['String'];
};

export enum EndOfEpisodeAction {
  PlayNextEpisode = 'PLAY_NEXT_EPISODE',
  ReplayEpisode = 'REPLAY_EPISODE',
  ReplaySeries = 'REPLAY_SERIES'
}

export type Episode = {
  __typename?: 'Episode';
  /** episode description */
  description: Scalars['String'];
  /** episode play time duration in seconds */
  durationInSeconds: Scalars['Int'];
  /** Indicates if the episode has a draft version */
  hasDraft: Scalars['Boolean'];
  /** Indicates if the episode has a new version */
  hasNewVersion: Scalars['Boolean'];
  /** unique identifier */
  id: Scalars['ID'];
  /** Indicates if the episode is new for the series */
  isNew: Scalars['Boolean'];
  /** media assets for the series marketing materials */
  media: EpisodeMedia;
  /** episode numeric order */
  order: Scalars['Int'];
  /** Total number of plays for the episode */
  playsCount: Scalars['Int'];
  /** Reference */
  ref?: Maybe<Scalars['String']>;
  /** Series id */
  seriesId: Scalars['ID'];
  /** availability status */
  status: EpisodeStatus;
  /** episode title */
  title: Scalars['String'];
  /** How much gems is needed to unlock the episode */
  unlockPrice?: Maybe<Scalars['Int']>;
  /**
   * User Episode ID, if exists
   * @deprecated userEpisodeId is deprecated. Use userEpisodeId inside of userMetrices instead.
   */
  userEpisodeId?: Maybe<Scalars['ID']>;
  /** user specific virtual attributes */
  userMetrics?: Maybe<UserEpisodeMetrics>;
};

export type EpisodeGenerationJob = {
  __typename?: 'EpisodeGenerationJob';
  episodeId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  seriesId?: Maybe<Scalars['ID']>;
  status: EpisodeGenerationStatus;
};

export enum EpisodeGenerationStatus {
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Succeeded = 'SUCCEEDED'
}

export enum EpisodeGenerationStatusError {
  NotFound = 'NOT_FOUND',
  Unknown = 'UNKNOWN'
}

export type EpisodeGenerationStatusInput = {
  /** Job ID of the generation to queue status for */
  jobId: Scalars['ID'];
};

export type EpisodeGenerationStatusResponse = {
  __typename?: 'EpisodeGenerationStatusResponse';
  error?: Maybe<Array<EpisodeGenerationStatusError>>;
  job?: Maybe<EpisodeGenerationJob>;
};

export type EpisodeMedia = {
  __typename?: 'EpisodeMedia';
  main: Scalars['String'];
  thumbnail: Scalars['String'];
};

export enum EpisodeStatus {
  Draft = 'DRAFT',
  NewVersionDraft = 'NEW_VERSION_DRAFT',
  Published = 'PUBLISHED'
}

export type EpisodeTemplate = {
  __typename?: 'EpisodeTemplate';
  form: EpisodeTemplateForm;
  id: Scalars['ID'];
};

export type EpisodeTemplateForm = {
  __typename?: 'EpisodeTemplateForm';
  fields: Array<EpisodeTemplateFormField>;
};

export type EpisodeTemplateFormField = {
  __typename?: 'EpisodeTemplateFormField';
  description: Scalars['String'];
  id: Scalars['String'];
  label: Scalars['String'];
  options?: Maybe<Array<Maybe<EpisodeTemplateFormFieldOption>>>;
  propertyName: Scalars['String'];
  type: EpisodeTemplateFormFieldType;
};

export type EpisodeTemplateFormFieldOption = {
  __typename?: 'EpisodeTemplateFormFieldOption';
  label: Scalars['String'];
  value: Scalars['String'];
};

export enum EpisodeTemplateFormFieldType {
  Number = 'NUMBER',
  Select = 'SELECT',
  String = 'STRING'
}

export type EpisodeTemplateQueryError = {
  __typename?: 'EpisodeTemplateQueryError';
  code: EpisodeTemplateQueryErrorCode;
  message: Scalars['String'];
};

export enum EpisodeTemplateQueryErrorCode {
  InternalServerError = 'INTERNAL_SERVER_ERROR',
  NotFound = 'NOT_FOUND'
}

export type EpisodeTemplateResponse = {
  __typename?: 'EpisodeTemplateResponse';
  error?: Maybe<Array<EpisodeTemplateQueryError>>;
  template?: Maybe<EpisodeTemplate>;
};

export enum EpisodesErrorCode {
  InvalidInput = 'INVALID_INPUT',
  Unknown = 'UNKNOWN'
}

export type EpisodesQueryError = DisplayableError & {
  __typename?: 'EpisodesQueryError';
  code: EpisodesErrorCode;
  message: Scalars['String'];
  source: Scalars['String'];
};

export type EpisodesQueryInput = {
  /** @deprecated Not used anymore */
  episodeRef?: InputMaybe<Scalars['String']>;
  includeDrafts?: InputMaybe<Scalars['Boolean']>;
  seriesId?: InputMaybe<Scalars['ID']>;
};

export type EpisodesResponse = {
  __typename?: 'EpisodesResponse';
  episodes: Array<Episode>;
  error?: Maybe<Array<EpisodesQueryError>>;
};

export type FeatureFlag = {
  __typename?: 'FeatureFlag';
  /** Feature flag key */
  key: Scalars['String'];
  /** Feature flag state */
  state: FeatureFlagState;
  /** Feature flag value */
  value?: Maybe<Scalars['JSON']>;
};

export type FeatureFlagError = DisplayableError & {
  __typename?: 'FeatureFlagError';
  code: FeatureFlagErrorCode;
  message: Scalars['String'];
  source: Scalars['String'];
};

export enum FeatureFlagErrorCode {
  InvalidInput = 'INVALID_INPUT',
  ProviderError = 'PROVIDER_ERROR',
  Unknown = 'UNKNOWN'
}

export enum FeatureFlagState {
  Disabled = 'DISABLED',
  Enabled = 'ENABLED'
}

export type FeatureFlagUserContextInput = {
  /** the browser users agent */
  appBuildNumber: Scalars['Int'];
};

export type GameCharacter = {
  __typename?: 'GameCharacter';
  name: Scalars['String'];
  role: GameRole;
};

export enum GameRole {
  Narrator = 'NARRATOR',
  NonPlayableCharacter = 'NON_PLAYABLE_CHARACTER',
  PlayableCharacter = 'PLAYABLE_CHARACTER'
}

export type GetFeatureFlagsInput = {
  /** additional context to be used for evaluation or segmentation */
  userContext: FeatureFlagUserContextInput;
};

export type GetFeatureFlagsResponse = {
  __typename?: 'GetFeatureFlagsResponse';
  error?: Maybe<Array<FeatureFlagError>>;
  featureFlags: Array<FeatureFlag>;
};

export type GetUserEpisodeError = {
  __typename?: 'GetUserEpisodeError';
  code: GetUserEpisodeErrorCode;
  message: Scalars['String'];
};

export enum GetUserEpisodeErrorCode {
  InsufficientCredits = 'INSUFFICIENT_CREDITS',
  NotFound = 'NOT_FOUND',
  PriceMismatch = 'PRICE_MISMATCH'
}

export enum ImpersonateUserError {
  NotFound = 'NOT_FOUND',
  Unknown = 'UNKNOWN'
}

export type ImpersonateUserInput = {
  /** The auth token to check fo */
  authToken: Scalars['String'];
  /** The userId to create a session for */
  impersonationUserId: Scalars['ID'];
};

export type ImpersonateUserResponse = {
  __typename?: 'ImpersonateUserResponse';
  error?: Maybe<Array<ImpersonateUserError>>;
  jwt?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type Interaction = {
  __typename?: 'Interaction';
  coinToss: InteractionResponse;
  continueEpisode: InteractionResponse;
  playerInput: InteractionResponse;
  rollDices: InteractionResponse;
  singleSelect: InteractionResponse;
  triggerItemAction: InteractionResponse;
};


export type InteractionCoinTossArgs = {
  input: CoinTossInteractionInput;
};


export type InteractionContinueEpisodeArgs = {
  input: ContinueEpisodeInput;
};


export type InteractionPlayerInputArgs = {
  input: PlayerInputInteractionInput;
};


export type InteractionRollDicesArgs = {
  input: DiceRollInteractionInput;
};


export type InteractionSingleSelectArgs = {
  input: SingleSelectInteractionInput;
};


export type InteractionTriggerItemActionArgs = {
  input: TriggerItemActionInput;
};

export type InteractionError = {
  __typename?: 'InteractionError';
  code: InteractionErrorCode;
  message: Scalars['String'];
};

export enum InteractionErrorCode {
  InvalidInteraction = 'INVALID_INTERACTION',
  NotFound = 'NOT_FOUND'
}

export type InteractionResponse = {
  __typename?: 'InteractionResponse';
  error?: Maybe<Array<InteractionError>>;
  hud?: Maybe<Array<UserEpisodeHudItem>>;
  messages?: Maybe<Array<UserEpisodeMessage>>;
};

export type InventoryItem = {
  __typename?: 'InventoryItem';
  action?: Maybe<InventoryItemAction>;
  category: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['ID'];
  imageUrl: Scalars['String'];
  itemId: Scalars['String'];
  location?: Maybe<Scalars['String']>;
  metadata?: Maybe<InventoryItemMetadata>;
  name: Scalars['String'];
  quantity: Scalars['Int'];
};

export type InventoryItemAction = {
  __typename?: 'InventoryItemAction';
  consumable: Scalars['Boolean'];
  label: Scalars['String'];
  ref: Scalars['String'];
};

export type InventoryItemEpisodeDetails = {
  __typename?: 'InventoryItemEpisodeDetails';
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type InventoryItemMetadata = {
  __typename?: 'InventoryItemMetadata';
  episode?: Maybe<Array<InventoryItemEpisodeDetails>>;
  owner?: Maybe<Array<InventoryItemOwnerDetails>>;
  series?: Maybe<Array<InventoryItemSeriesDetails>>;
};

export type InventoryItemOwnerDetails = {
  __typename?: 'InventoryItemOwnerDetails';
  name?: Maybe<Scalars['String']>;
};

export type InventoryItemSeriesDetails = {
  __typename?: 'InventoryItemSeriesDetails';
  name?: Maybe<Scalars['String']>;
};

export type InventoryQueryError = DisplayableError & {
  __typename?: 'InventoryQueryError';
  code: InventoryQueryErrorCode;
  message: Scalars['String'];
  source: Scalars['String'];
};

export enum InventoryQueryErrorCode {
  InvalidInput = 'INVALID_INPUT',
  NotFound = 'NOT_FOUND',
  Unauthorized = 'UNAUTHORIZED',
  Unknown = 'UNKNOWN'
}

export type InventoryQueryInput = {
  characterId?: InputMaybe<Scalars['ID']>;
  userEpisodeId?: InputMaybe<Scalars['ID']>;
};

export type InventoryResponse = {
  __typename?: 'InventoryResponse';
  error?: Maybe<Array<InventoryQueryError>>;
  items?: Maybe<Array<InventoryItem>>;
};

export type LikeSeriesError = DisplayableError & {
  __typename?: 'LikeSeriesError';
  code: LikeSeriesErrorCode;
  message: Scalars['String'];
  source: Scalars['String'];
};

export enum LikeSeriesErrorCode {
  InvalidInput = 'INVALID_INPUT',
  SeriesNotFound = 'SERIES_NOT_FOUND'
}

export type LikeSeriesInput = {
  like: Scalars['Boolean'];
  seriesId: Scalars['ID'];
};

export type LikeSeriesResponse = {
  __typename?: 'LikeSeriesResponse';
  error?: Maybe<Array<LikeSeriesError>>;
};

export enum MaestroEpisodesErrorCode {
  InvalidInput = 'INVALID_INPUT',
  Unauthorized = 'UNAUTHORIZED',
  Unknown = 'UNKNOWN'
}

export type MaestroEpisodesQueryError = DisplayableError & {
  __typename?: 'MaestroEpisodesQueryError';
  code: MaestroEpisodesErrorCode;
  message: Scalars['String'];
  source: Scalars['String'];
};

export type MaestroEpisodesQueryInput = {
  /** boolean to force drafts first */
  draftsFirst?: InputMaybe<Scalars['Boolean']>;
  /** pagination limit */
  limit?: InputMaybe<Scalars['Int']>;
  /** pagination offset */
  offset?: InputMaybe<Scalars['Int']>;
  /** Series ID to filter results by */
  seriesId?: InputMaybe<Scalars['ID']>;
};

export type MaestroEpisodesResponse = {
  __typename?: 'MaestroEpisodesResponse';
  episodes?: Maybe<Array<StudioEpisode>>;
  error?: Maybe<Array<MaestroEpisodesQueryError>>;
  metadata?: Maybe<PaginatedResultMetadata>;
};

export type MaestroSeries = {
  __typename?: 'MaestroSeries';
  /** the name of the author of the series */
  authorName?: Maybe<Scalars['String']>;
  /** the description of the series */
  description?: Maybe<Scalars['String']>;
  /** genre details */
  genre?: Maybe<MaestroSeriesGenreDetails>;
  /** genre id */
  genreId?: Maybe<Scalars['String']>;
  /** unique identifier */
  id: Scalars['ID'];
  /** likes count */
  likesCount: Scalars['Int'];
  /** media assets for the series marketing materials */
  media?: Maybe<SeriesMedia>;
  /** the overview of the series */
  overview?: Maybe<Scalars['String']>;
  /** Play count */
  playsCount: Scalars['Int'];
  /** title of the series */
  title?: Maybe<Scalars['String']>;
  /** world details */
  world?: Maybe<MaestroSeriesWorldDetails>;
  /** id of the world assigned to this series */
  worldId?: Maybe<Scalars['String']>;
};

export enum MaestroSeriesErrorCode {
  InvalidInput = 'INVALID_INPUT',
  Unauthorized = 'UNAUTHORIZED',
  Unknown = 'UNKNOWN'
}

export type MaestroSeriesGenreDetails = {
  __typename?: 'MaestroSeriesGenreDetails';
  /** unique identifier */
  id: Scalars['ID'];
  /** title of the genre */
  title: Scalars['String'];
};

export type MaestroSeriesQueryError = DisplayableError & {
  __typename?: 'MaestroSeriesQueryError';
  code: MaestroSeriesErrorCode;
  message: Scalars['String'];
  source: Scalars['String'];
};

export type MaestroSeriesQueryInput = {
  /** Series ID to filter results by */
  seriesId: Scalars['ID'];
};

export type MaestroSeriesResponse = {
  __typename?: 'MaestroSeriesResponse';
  error?: Maybe<Array<MaestroSeriesQueryError>>;
  series?: Maybe<MaestroSeries>;
};

export type MaestroSeriesWorldDetails = {
  __typename?: 'MaestroSeriesWorldDetails';
  /** unique identifier */
  id: Scalars['ID'];
  /** title of the world */
  title: Scalars['String'];
};

export type MinifiedEpisode = {
  __typename?: 'MinifiedEpisode';
  author?: Maybe<Scalars['String']>;
  coverUrl?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  hasDraft: Scalars['Boolean'];
  hasNewVersion: Scalars['Boolean'];
  id: Scalars['ID'];
  isAuthor: Scalars['Boolean'];
  playsCount: Scalars['Int'];
  seriesId: Scalars['ID'];
  title: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptDraftEpisodeInvitation: AcceptDraftEpisodeInvitationMutationResponse;
  authenticateWithProvider: AuthenticateWithProviderResponse;
  clearUserEpisodesInSeries: ClearUserEpisodesInSeriesResponse;
  createAiEpisodeJob: CreateAiEpisodeJobResponse;
  createEmulatorSession: CreateEmulatorSessionResponse;
  createEpisode: CreateEpisodeResponse;
  createSeries: CreateSeriesResponse;
  createUploadUrl: CreateSignedUploadUrResponse;
  createUserEpisode: CreateUserEpisodeResponse;
  deleteAccount: DeleteAccountResponse;
  discoverySection: DiscoverySectionMutations;
  impersonateUser: ImpersonateUserResponse;
  interaction: Interaction;
  likeSeries: LikeSeriesResponse;
  logout?: Maybe<Scalars['Void']>;
  publishEpisode: PublishEpisodeResponse;
  realtimeToken: RealtimeTokenResponse;
  setMessageViewed: SetMessageViewedResponse;
  updateEpisode: UpdateEpisodeResponse;
  updateNotificationPreferences: UpdateNotificationPreferencesResponse;
  updateSeries: UpdateSeriesResponse;
  updateSeriesRpgConfig: RpgConfigResponse;
  updateUser: UpdateUserResponse;
  verifyReceipt: VerifyReceiptResponse;
};


export type MutationAcceptDraftEpisodeInvitationArgs = {
  input: AcceptDraftEpisodeInvitationMutationInput;
};


export type MutationAuthenticateWithProviderArgs = {
  input: AuthenticateWithProviderInput;
};


export type MutationClearUserEpisodesInSeriesArgs = {
  input: ClearUserEpisodesInSeriesInput;
};


export type MutationCreateAiEpisodeJobArgs = {
  input: CreateAiEpisodeJobInput;
};


export type MutationCreateEpisodeArgs = {
  input: CreateEpisodeInput;
};


export type MutationCreateSeriesArgs = {
  input: CreateSeriesInput;
};


export type MutationCreateUploadUrlArgs = {
  input: CreateSignedUploadUrlInput;
};


export type MutationCreateUserEpisodeArgs = {
  episodeId: Scalars['ID'];
};


export type MutationImpersonateUserArgs = {
  input: ImpersonateUserInput;
};


export type MutationLikeSeriesArgs = {
  input: LikeSeriesInput;
};


export type MutationPublishEpisodeArgs = {
  input: PublishEpisodeInput;
};


export type MutationSetMessageViewedArgs = {
  input: SetMessageViewedInput;
};


export type MutationUpdateEpisodeArgs = {
  input: UpdateEpisodeInput;
};


export type MutationUpdateNotificationPreferencesArgs = {
  input: UpdateNotificationPreferencesInput;
};


export type MutationUpdateSeriesArgs = {
  input: UpdateSeriesInput;
};


export type MutationUpdateSeriesRpgConfigArgs = {
  input: UpdateSeriesRpgConfigInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationVerifyReceiptArgs = {
  input: VerifyReceiptInput;
};

export type NotificationPreference = {
  __typename?: 'NotificationPreference';
  displayName: Scalars['String'];
  displayOrder: Scalars['Int'];
  id: Scalars['ID'];
  isEnabled: Scalars['Boolean'];
};

export enum NotificationPreferencesError {
  ProviderError = 'PROVIDER_ERROR',
  Unauthenticated = 'UNAUTHENTICATED',
  Unknown = 'UNKNOWN'
}

export type NotificationPreferencesResponse = {
  __typename?: 'NotificationPreferencesResponse';
  error?: Maybe<Array<NotificationPreferencesError>>;
  preferences: Array<NotificationPreference>;
};

export type PageCursor = {
  __typename?: 'PageCursor';
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
};

export type PaginatedResultMetadata = {
  __typename?: 'PaginatedResultMetadata';
  hasMore: Scalars['Boolean'];
  nextCursor?: Maybe<PageCursor>;
};

export type PlayerInputInteractionInput = {
  input: Scalars['String'];
  interactionId: Scalars['String'];
  userEpisodeId: Scalars['ID'];
};

export type PrePublishEpisodeVersionValidation = {
  __typename?: 'PrePublishEpisodeVersionValidation';
  field: Scalars['String'];
  message: Scalars['String'];
};

export type PrePublishEpisodeVersionValidationError = {
  __typename?: 'PrePublishEpisodeVersionValidationError';
  code: PrePublishEpisodeVersionValidationErrorCode;
  message: Scalars['String'];
};

export enum PrePublishEpisodeVersionValidationErrorCode {
  InvalidInput = 'INVALID_INPUT',
  Unauthorized = 'UNAUTHORIZED',
  Unknown = 'UNKNOWN'
}

export type PrePublishEpisodeVersionValidationInput = {
  episodeId: Scalars['ID'];
};

export type PrePublishEpisodeVersionValidationResponse = {
  __typename?: 'PrePublishEpisodeVersionValidationResponse';
  episodeValidations?: Maybe<Array<PrePublishEpisodeVersionValidation>>;
  error?: Maybe<Array<PrePublishEpisodeVersionValidationError>>;
  seriesValidations?: Maybe<Array<PrePublishEpisodeVersionValidation>>;
  status?: Maybe<PrePublishEpisodeVersionValidationStatus>;
};

export enum PrePublishEpisodeVersionValidationStatus {
  Invalid = 'INVALID',
  Valid = 'VALID'
}

export type Profile = {
  __typename?: 'Profile';
  email?: Maybe<Scalars['String']>;
  initials?: Maybe<Scalars['String']>;
  memberSince: Scalars['String'];
};

export type ProfileQueryError = DisplayableError & {
  __typename?: 'ProfileQueryError';
  code: ProfileQueryErrorCode;
  message: Scalars['String'];
  source: Scalars['String'];
};

export enum ProfileQueryErrorCode {
  Unknown = 'UNKNOWN'
}

export type ProfileResponse = {
  __typename?: 'ProfileResponse';
  error?: Maybe<Array<ProfileQueryError>>;
  profile?: Maybe<Profile>;
};

export type ProviderMetadata = {
  deviceType: DeviceType;
  /** Autentication nonce provided by the provider; only required for Apple */
  nonce?: InputMaybe<Scalars['String']>;
};

export type PublishEpisodeError = {
  __typename?: 'PublishEpisodeError';
  code: PublishEpisodeErrorCode;
  message: Scalars['String'];
};

export enum PublishEpisodeErrorCode {
  EpisodeFlowMissingContent = 'EPISODE_FLOW_MISSING_CONTENT',
  InvalidInput = 'INVALID_INPUT',
  Unauthorized = 'UNAUTHORIZED',
  Unknown = 'UNKNOWN'
}

export type PublishEpisodeInput = {
  episodeId: Scalars['ID'];
};

export type PublishEpisodeResponse = {
  __typename?: 'PublishEpisodeResponse';
  error?: Maybe<Array<PublishEpisodeError>>;
};

export type PublishedEpisodeVersion = {
  __typename?: 'PublishedEpisodeVersion';
  id: Scalars['ID'];
  publishedAt: Scalars['DateTime'];
  studioFlow: Scalars['JSON'];
};

export type Query = {
  __typename?: 'Query';
  appUpdateData: AppUpdateData;
  createSeriesSatelliteData: CreateSeriesSatelliteDataResponse;
  creditActivity: Array<CreditActivity>;
  /** No need to have arguments or different type */
  credits: Scalars['Int'];
  discovery: DiscoveryResponse;
  episodeGenerationStatus: EpisodeGenerationStatusResponse;
  episodeTemplate: EpisodeTemplateResponse;
  episodes: EpisodesResponse;
  featureFlags: GetFeatureFlagsResponse;
  inventory: InventoryResponse;
  maestroEpisodes: MaestroEpisodesResponse;
  maestroSeries: MaestroSeriesResponse;
  notificationPreferences: NotificationPreferencesResponse;
  prePublishEpisodeVersionValidation: PrePublishEpisodeVersionValidationResponse;
  /** @deprecated No longer called by Enigma client */
  profile: ProfileResponse;
  recentEpisodes: RecentEpisodesResponse;
  series: SeriesResponse;
  seriesGenres: SeriesGenresResponse;
  studioEpisode: StudioEpisodeQueryResponse;
  userEpisode: UserEpisodeResponse;
  userEpisodeStats: UserEpisodeStatsResponse;
  userSession: UserSessionResponse;
  waitForTransactionReceipt: WaitForTransactionReceiptResponse;
  world: WorldResponse;
  worlds: WorldsResponse;
};


export type QueryCreditActivityArgs = {
  input?: InputMaybe<CreditActivityInput>;
};


export type QueryDiscoveryArgs = {
  input?: InputMaybe<DiscoveryInput>;
};


export type QueryEpisodeGenerationStatusArgs = {
  input: EpisodeGenerationStatusInput;
};


export type QueryEpisodesArgs = {
  input?: InputMaybe<EpisodesQueryInput>;
};


export type QueryFeatureFlagsArgs = {
  input: GetFeatureFlagsInput;
};


export type QueryInventoryArgs = {
  characterId?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<InventoryQueryInput>;
};


export type QueryMaestroEpisodesArgs = {
  input: MaestroEpisodesQueryInput;
};


export type QueryMaestroSeriesArgs = {
  input: MaestroSeriesQueryInput;
};


export type QueryPrePublishEpisodeVersionValidationArgs = {
  input: PrePublishEpisodeVersionValidationInput;
};


export type QueryRecentEpisodesArgs = {
  input?: InputMaybe<RecentEpisodesInput>;
};


export type QuerySeriesArgs = {
  input?: InputMaybe<SeriesQueryInput>;
};


export type QueryStudioEpisodeArgs = {
  input: StudioEpisodeQueryInput;
};


export type QueryUserEpisodeArgs = {
  episodeId?: InputMaybe<Scalars['ID']>;
  episodePrice?: InputMaybe<Scalars['Int']>;
  useDraft?: InputMaybe<Scalars['Boolean']>;
  useNewVersion?: InputMaybe<Scalars['Boolean']>;
  userEpisodeId?: InputMaybe<Scalars['ID']>;
};


export type QueryWaitForTransactionReceiptArgs = {
  txHash: Scalars['String'];
};


export type QueryWorldArgs = {
  id: Scalars['ID'];
};


export type QueryWorldsArgs = {
  input?: InputMaybe<WorldsInput>;
};

export type RealtimeToken = {
  __typename?: 'RealtimeToken';
  capability: Scalars['String'];
  expires: Scalars['Float'];
  issued: Scalars['Float'];
  token: Scalars['String'];
};

export type RealtimeTokenError = DisplayableError & {
  __typename?: 'RealtimeTokenError';
  code: RealtimeTokenErrorCode;
  message: Scalars['String'];
  source: Scalars['String'];
};

export enum RealtimeTokenErrorCode {
  ProviderError = 'PROVIDER_ERROR',
  Unknown = 'UNKNOWN'
}

export type RealtimeTokenResponse = {
  __typename?: 'RealtimeTokenResponse';
  error?: Maybe<Array<RealtimeTokenError>>;
  token?: Maybe<RealtimeToken>;
};

export type RecentEpisode = {
  __typename?: 'RecentEpisode';
  completionPercentage: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  episodeId: Scalars['ID'];
  /** Indicates if the episode has a draft version */
  hasDraft: Scalars['Boolean'];
  /** Indicates if the episode has a new version */
  hasNewVersion: Scalars['Boolean'];
  imageUrl: Scalars['String'];
  seriesId: Scalars['ID'];
  seriesTitle: Scalars['String'];
  title: Scalars['String'];
  userEpisodeId: Scalars['ID'];
};

export enum RecentEpisodesErrorCode {
  NotFound = 'NOT_FOUND'
}

export type RecentEpisodesInput = {
  filterCreatedByMe?: InputMaybe<Scalars['Boolean']>;
};

export type RecentEpisodesResponse = {
  __typename?: 'RecentEpisodesResponse';
  error?: Maybe<Array<RecentEpisodesErrorCode>>;
  recentEpisodes?: Maybe<Array<RecentEpisode>>;
};

export type RpgConfigResponse = {
  __typename?: 'RpgConfigResponse';
  error?: Maybe<Array<SeriesQueryError>>;
};

export type Series = {
  __typename?: 'Series';
  /** the name of the author of the series */
  authorName?: Maybe<Scalars['String']>;
  /**
   * if the series is created by the world creator
   * @deprecated Will be deleted soon
   */
  createdByWorldCreator: Scalars['Boolean'];
  /** the current episode in the series */
  currentEpisode?: Maybe<Episode>;
  /** the description of the series */
  description: Scalars['String'];
  /** the number of episodes in the series */
  episodeCount: Scalars['Int'];
  /** the genre of the series */
  genre: Scalars['String'];
  /** indicates if the series has a new episode */
  hasNewEpisode: Scalars['Boolean'];
  /** unique identifier */
  id: Scalars['ID'];
  /** Whether the user is liking the series or not */
  isLiked?: Maybe<Scalars['Boolean']>;
  /** Number of likes this series has */
  likesCount: Scalars['Int'];
  /** media assets for the series marketing materials */
  media: SeriesMedia;
  /**
   * total time to play all episodes in the series
   * @deprecated Will be deleted soon
   */
  playbackTimeInSeconds: Scalars['Int'];
  /** Number of plays this series has with all its episodes */
  playsCount: Scalars['Int'];
  /**
   * the date and time the series will be launched with timezone
   * @deprecated Will be deleted soon
   */
  releaseDateTime: Scalars['DateTime'];
  /**
   * the availability status of the series
   * @deprecated The notion of a Series status has been removed. View an Episodes publish status for information.
   */
  status: SeriesStatus;
  /** title of the series */
  title: Scalars['String'];
};

export enum SeriesErrorCode {
  InvalidInput = 'INVALID_INPUT',
  NotFound = 'NOT_FOUND',
  Unauthorized = 'UNAUTHORIZED',
  Unknown = 'UNKNOWN'
}

export type SeriesGenre = {
  __typename?: 'SeriesGenre';
  /** Unique Series Genre ID */
  id: Scalars['ID'];
  /** Name of the Series Genre */
  name: Scalars['String'];
  /** Count of all series that are a part of this genre */
  seriesCount: Scalars['Int'];
};

export enum SeriesGenresQueryErrorCode {
  Unknown = 'UNKNOWN'
}

export type SeriesGenresResponse = {
  __typename?: 'SeriesGenresResponse';
  error?: Maybe<Array<SeriesGenresQueryErrorCode>>;
  genres?: Maybe<Array<SeriesGenre>>;
};

export type SeriesMedia = {
  __typename?: 'SeriesMedia';
  main: Scalars['String'];
  thumbnail: Scalars['String'];
};

export type SeriesQueryError = DisplayableError & {
  __typename?: 'SeriesQueryError';
  code: SeriesErrorCode;
  message: Scalars['String'];
  source: Scalars['String'];
};

export type SeriesQueryInput = {
  /** Boolean flag to filter created by the user */
  filterCreatedByMe?: InputMaybe<Scalars['Boolean']>;
  /** Genre ID to filter results by */
  genreId?: InputMaybe<Scalars['ID']>;
  /** Series ID to filter results by */
  seriesId?: InputMaybe<Scalars['ID']>;
  /** Indicate how to sort the list */
  sortBy?: InputMaybe<SeriesSort>;
  /** World ID to filter results by */
  worldId?: InputMaybe<Scalars['ID']>;
};

export type SeriesResponse = {
  __typename?: 'SeriesResponse';
  error?: Maybe<Array<SeriesQueryError>>;
  series: Array<Series>;
};

export enum SeriesSort {
  Alphabetically = 'ALPHABETICALLY',
  MostLiked = 'MOST_LIKED',
  MostPlayed = 'MOST_PLAYED',
  RecentlyUpdated = 'RECENTLY_UPDATED'
}

export enum SeriesStatus {
  Published = 'PUBLISHED'
}

export type SetMessageViewedError = {
  __typename?: 'SetMessageViewedError';
  code: SetMessageViewedErrorCode;
  message: Scalars['String'];
};

export enum SetMessageViewedErrorCode {
  MessageNotFound = 'MESSAGE_NOT_FOUND'
}

export type SetMessageViewedInput = {
  messageId: Scalars['ID'];
};

export type SetMessageViewedResponse = {
  __typename?: 'SetMessageViewedResponse';
  error?: Maybe<Array<SetMessageViewedError>>;
};

export type SingleSelectInteractionInput = {
  interactionId: Scalars['String'];
  optionId: Scalars['String'];
  userEpisodeId: Scalars['ID'];
};

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum StorageObjectType {
  Character = 'CHARACTER'
}

export type StudioEpisode = {
  __typename?: 'StudioEpisode';
  /** episode description */
  description: Scalars['String'];
  /** draft details */
  draftVersion: DraftEpisodeVersion;
  /** unique identifier */
  id: Scalars['ID'];
  /** media assets for the series marketing materials */
  media: EpisodeMedia;
  /** indicates the order of the episode */
  order?: Maybe<Scalars['Int']>;
  /** numeric count of user episodes */
  playsCount: Scalars['Int'];
  /** published details */
  publishedVersion?: Maybe<PublishedEpisodeVersion>;
  /** Reference */
  ref?: Maybe<Scalars['String']>;
  /** series details */
  series: StudioEpisodeSeriesDetails;
  /** episode title */
  title: Scalars['String'];
};

export type StudioEpisodeQueryError = {
  __typename?: 'StudioEpisodeQueryError';
  code: StudioEpisodeQueryErrorCode;
  message: Scalars['String'];
};

export enum StudioEpisodeQueryErrorCode {
  NotFound = 'NOT_FOUND',
  Unauthorized = 'UNAUTHORIZED',
  Unknown = 'UNKNOWN'
}

export type StudioEpisodeQueryInput = {
  episodeId: Scalars['ID'];
};

export type StudioEpisodeQueryResponse = {
  __typename?: 'StudioEpisodeQueryResponse';
  data?: Maybe<StudioEpisode>;
  error?: Maybe<Array<StudioEpisodeQueryError>>;
};

export type StudioEpisodeSeriesDetails = {
  __typename?: 'StudioEpisodeSeriesDetails';
  authorId: Scalars['ID'];
  id: Scalars['ID'];
  rpgConfig?: Maybe<Scalars['JSON']>;
  title: Scalars['String'];
};

export type TransactionReceipt = {
  __typename?: 'TransactionReceipt';
  blockHash: Scalars['String'];
  contractAddress?: Maybe<Scalars['String']>;
  from: Scalars['String'];
  status: TransactionStatus;
  to?: Maybe<Scalars['String']>;
  transactionHash: Scalars['String'];
};

export enum TransactionStatus {
  Reverted = 'REVERTED',
  Success = 'SUCCESS'
}

export type TriggerItemActionInput = {
  /** @deprecated Use itemId instead */
  characterItemId?: InputMaybe<Scalars['ID']>;
  itemId?: InputMaybe<Scalars['ID']>;
  userEpisodeId: Scalars['ID'];
};

export type UpdateDiscoverySectionInput = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  onlyForKids?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<Scalars['Int']>;
  series?: InputMaybe<Array<Scalars['ID']>>;
};

export type UpdateDiscoverySectionResponse = {
  __typename?: 'UpdateDiscoverySectionResponse';
  error?: Maybe<Array<DiscoveryError>>;
};

export type UpdateEpisodeError = {
  __typename?: 'UpdateEpisodeError';
  code: UpdateEpisodeErrorCode;
  message: Scalars['String'];
};

export enum UpdateEpisodeErrorCode {
  InvalidInput = 'INVALID_INPUT',
  Unauthorized = 'UNAUTHORIZED',
  Unknown = 'UNKNOWN'
}

export type UpdateEpisodeInput = {
  coverImageUrl?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateEpisodeResponse = {
  __typename?: 'UpdateEpisodeResponse';
  episode?: Maybe<UpdatedEpisodeDetails>;
  error?: Maybe<Array<UpdateEpisodeError>>;
};

export type UpdateNotificationPreferenceInput = {
  id: Scalars['ID'];
  isEnabled: Scalars['Boolean'];
};

export type UpdateNotificationPreferencesInput = {
  preferences: Array<UpdateNotificationPreferenceInput>;
};

export type UpdateNotificationPreferencesResponse = {
  __typename?: 'UpdateNotificationPreferencesResponse';
  error?: Maybe<Array<NotificationPreferencesError>>;
  preferences: Array<NotificationPreference>;
};

export type UpdateSeriesError = {
  __typename?: 'UpdateSeriesError';
  code: UpdateSeriesErrorCode;
  message: Scalars['String'];
};

export enum UpdateSeriesErrorCode {
  InvalidInput = 'INVALID_INPUT',
  Unauthorized = 'UNAUTHORIZED',
  Unknown = 'UNKNOWN'
}

export type UpdateSeriesInput = {
  coverImageUrl?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  genreId?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  /** @deprecated Use `description` instead */
  overview?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  worldId?: InputMaybe<Scalars['ID']>;
};

export type UpdateSeriesResponse = {
  __typename?: 'UpdateSeriesResponse';
  error?: Maybe<Array<UpdateSeriesError>>;
  series?: Maybe<Series>;
};

export type UpdateSeriesRpgConfigInput = {
  rpgConfig: Scalars['JSON'];
  seriesId: Scalars['ID'];
};

export enum UpdateUserErrorCode {
  NotFound = 'NOT_FOUND',
  Unknown = 'UNKNOWN'
}

export type UpdateUserInput = {
  showOnlyKidsContent?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateUserResponse = {
  __typename?: 'UpdateUserResponse';
  error?: Maybe<Array<UpdateUserErrorCode>>;
  user?: Maybe<User>;
};

export type UpdatedEpisodeDetails = {
  __typename?: 'UpdatedEpisodeDetails';
  description: Scalars['String'];
  id: Scalars['ID'];
  media: EpisodeMedia;
  title: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  authProviders: Array<AuthenticationProvider>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** @deprecated Onboarding is removed from the app */
  onboarded: Scalars['Boolean'];
  showOnlyKidsContent?: Maybe<Scalars['Boolean']>;
  walletAddress?: Maybe<Scalars['String']>;
};

export type UserEpisode = {
  __typename?: 'UserEpisode';
  /** Will be used to subscribe to the channel */
  channelId: Scalars['String'];
  /**
   * character details
   * @deprecated not needed
   */
  character: Character;
  /** Indicates the percentage value of the played time of the episode */
  completionPercentage: Scalars['Int'];
  /** @deprecated not needed */
  episode: Episode;
  /** The Episode ID */
  episodeId: Scalars['ID'];
  /** Episode HUD to display in the game session, like health, energy, etc. */
  hud: Array<UserEpisodeHudItem>;
  /** Game Session ID */
  id: Scalars['ID'];
  /** Indicate if this UserEpisode is a draft */
  isDraft: Scalars['Boolean'];
  /** All messages that were previously sent on this game session */
  messages: Array<UserEpisodeMessage>;
  /** List of images to be prefetched for the game session */
  prefetchImageList: Array<Scalars['String']>;
  /** Current status of the game session */
  status: UserEpisodeStatus;
};

export type UserEpisodeCharacterCreatedMessage = {
  __typename?: 'UserEpisodeCharacterCreatedMessage';
  /** @deprecated Character attributes have been moved to the series level */
  attributes: Array<CharacterAttributeValue>;
  characterAvatarUrl?: Maybe<Scalars['String']>;
  characterName: Scalars['String'];
  /** @deprecated Character attributes have been moved to the series level */
  mainAttributeRef: Scalars['String'];
};

export type UserEpisodeCharacterCreatorCompleteMessage = {
  __typename?: 'UserEpisodeCharacterCreatorCompleteMessage';
  content: Scalars['String'];
};

export type UserEpisodeCharacterMainAttributeMessage = {
  __typename?: 'UserEpisodeCharacterMainAttributeMessage';
  /** @deprecated Character attributes have been moved to the series level */
  attributes: Array<CharacterAttribute>;
};

export type UserEpisodeCharacterRollAttributesMessage = {
  __typename?: 'UserEpisodeCharacterRollAttributesMessage';
  /** @deprecated Character attributes have been moved to the series level */
  mainAttribute: CharacterAttributeValue;
  /** @deprecated Character attributes have been moved to the series level */
  rolledAttributes: Array<CharacterAttributeValue>;
};

export type UserEpisodeCoinTossInteractionMessage = {
  __typename?: 'UserEpisodeCoinTossInteractionMessage';
  /** Attribute used to determine the outcome of the coin toss. */
  attribute: CharacterAttribute;
  id: Scalars['String'];
  /** The odds for the coin toss to be successful. 1%-100% */
  successRate: Scalars['Int'];
  /** Populated if there were a try on this coin toss */
  tossResult?: Maybe<CoinTossResult>;
};

export type UserEpisodeDetails = {
  __typename?: 'UserEpisodeDetails';
  completionPercentage: Scalars['Int'];
  episodeId: Scalars['ID'];
  id: Scalars['ID'];
};

export type UserEpisodeDiceRollInteractionMessage = {
  __typename?: 'UserEpisodeDiceRollInteractionMessage';
  classDifficulty?: Maybe<Scalars['Int']>;
  dices: Array<Dice>;
  id: Scalars['String'];
  modifier: Scalars['Int'];
  /** Populated when dice is rolled. */
  rollResult?: Maybe<Array<DiceRollResult>>;
};

export type UserEpisodeEndOfEpisodeMessage = {
  __typename?: 'UserEpisodeEndOfEpisodeMessage';
  action?: Maybe<EndOfEpisodeAction>;
  message?: Maybe<Scalars['String']>;
};

export type UserEpisodeHudItem = {
  __typename?: 'UserEpisodeHudItem';
  iconUrl: Scalars['String'];
  id: Scalars['ID'];
  label: Scalars['String'];
  maxValue?: Maybe<Scalars['Int']>;
  value: Scalars['Int'];
};

export type UserEpisodeImageMessage = {
  __typename?: 'UserEpisodeImageMessage';
  alt: Scalars['String'];
  height?: Maybe<Scalars['Int']>;
  imageUrl: Scalars['String'];
  width?: Maybe<Scalars['Int']>;
};

export type UserEpisodeInteraction = {
  __typename?: 'UserEpisodeInteraction';
  action: Scalars['String'];
};

export type UserEpisodeInventoryItemMessage = {
  __typename?: 'UserEpisodeInventoryItemMessage';
  delta: Scalars['Int'];
  inventoryItem: InventoryItem;
  /** @deprecated Use inventoryItem */
  itemDescription: Scalars['String'];
  /** @deprecated Use inventoryItem */
  itemId: Scalars['String'];
  /** @deprecated Use inventoryItem */
  itemImage: Scalars['String'];
  /** @deprecated Use inventoryItem */
  itemName: Scalars['String'];
};

export type UserEpisodeLocationMessage = {
  __typename?: 'UserEpisodeLocationMessage';
  location: Scalars['String'];
  ref: Scalars['String'];
};

export type UserEpisodeMessage = {
  __typename?: 'UserEpisodeMessage';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  payload?: Maybe<UserEpisodeMessagePayload>;
  type: UserEpisodeMessageType;
  viewed?: Maybe<Scalars['Boolean']>;
};

export type UserEpisodeMessagePayload = UserEpisodeCharacterCreatedMessage | UserEpisodeCharacterCreatorCompleteMessage | UserEpisodeCharacterMainAttributeMessage | UserEpisodeCharacterRollAttributesMessage | UserEpisodeCoinTossInteractionMessage | UserEpisodeDiceRollInteractionMessage | UserEpisodeEndOfEpisodeMessage | UserEpisodeImageMessage | UserEpisodeInventoryItemMessage | UserEpisodeLocationMessage | UserEpisodeObjectiveCompletedMessage | UserEpisodeOnboardingCompleteMessage | UserEpisodePlayerInputMessage | UserEpisodePlayerInputResultMessage | UserEpisodeSelectedInteractionMessage | UserEpisodeSingleSelectInteractionMessage | UserEpisodeStatBlockMessage | UserEpisodeTextMessage;

export enum UserEpisodeMessageType {
  /** @deprecated Delete it */
  CharacterCreated = 'CHARACTER_CREATED',
  /** @deprecated Delete it */
  CharacterCreatorComplete = 'CHARACTER_CREATOR_COMPLETE',
  /** @deprecated Delete it */
  CharacterImageUpload = 'CHARACTER_IMAGE_UPLOAD',
  /** @deprecated Delete it */
  CharacterMainAttribute = 'CHARACTER_MAIN_ATTRIBUTE',
  /** @deprecated Delete it */
  CharacterNameInput = 'CHARACTER_NAME_INPUT',
  /** @deprecated Delete it */
  CharacterRollAttribute = 'CHARACTER_ROLL_ATTRIBUTE',
  CoinTossInteraction = 'COIN_TOSS_INTERACTION',
  DiceRoll = 'DICE_ROLL',
  EndOfEpisode = 'END_OF_EPISODE',
  Image = 'IMAGE',
  InventoryItem = 'INVENTORY_ITEM',
  /** @deprecated Delete it */
  Location = 'LOCATION',
  /** @deprecated Replaced by END_OF_EPISODE */
  ObjectiveCompleted = 'OBJECTIVE_COMPLETED',
  /** @deprecated Delete it */
  OnboardingComplete = 'ONBOARDING_COMPLETE',
  PlayerInput = 'PLAYER_INPUT',
  PlayerInputResult = 'PLAYER_INPUT_RESULT',
  SelectedInteraction = 'SELECTED_INTERACTION',
  SingleSelectInteraction = 'SINGLE_SELECT_INTERACTION',
  StatBlock = 'STAT_BLOCK',
  Text = 'TEXT',
  Unknown = 'UNKNOWN'
}

export type UserEpisodeMetrics = {
  __typename?: 'UserEpisodeMetrics';
  /** The number of play-throughs that a user has completed for this episode */
  completedEpisodeCount: Scalars['Int'];
  /** Indicates the percentage value of the played time of the episode. */
  completionPercentage: Scalars['Int'];
  /** Indicates if the episode is locked for the user */
  isLocked: Scalars['Boolean'];
  /** Indicates if the episode requires the user to link auth before entering */
  requiresLinkedAuth: Scalars['Boolean'];
  /** The user's latest episode ID - will only exist if user has started the episode */
  userEpisodeId?: Maybe<Scalars['ID']>;
};

export type UserEpisodeObjective = {
  __typename?: 'UserEpisodeObjective';
  completionPercentage: Scalars['Int'];
  hasCompleted: Scalars['Boolean'];
  hidden: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  ref: Scalars['ID'];
};

export type UserEpisodeObjectiveCompletedMessage = {
  __typename?: 'UserEpisodeObjectiveCompletedMessage';
  name: Scalars['String'];
  ref: Scalars['String'];
};

export type UserEpisodeOnboardingCompleteMessage = {
  __typename?: 'UserEpisodeOnboardingCompleteMessage';
  content: Scalars['String'];
};

export type UserEpisodePlayerInputMessage = {
  __typename?: 'UserEpisodePlayerInputMessage';
  id: Scalars['String'];
  maxLength?: Maybe<Scalars['Int']>;
  prompt: Scalars['String'];
};

export type UserEpisodePlayerInputResultMessage = {
  __typename?: 'UserEpisodePlayerInputResultMessage';
  id: Scalars['String'];
  input: Scalars['String'];
};

export type UserEpisodeResponse = {
  __typename?: 'UserEpisodeResponse';
  data?: Maybe<UserEpisode>;
  error?: Maybe<Array<GetUserEpisodeError>>;
};

export type UserEpisodeSelectedInteractionMessage = {
  __typename?: 'UserEpisodeSelectedInteractionMessage';
  id: Scalars['String'];
  selectedOption: UserEpisodeSingleSelectOption;
};

export type UserEpisodeSingleSelectInteractionMessage = {
  __typename?: 'UserEpisodeSingleSelectInteractionMessage';
  id: Scalars['String'];
  options: Array<UserEpisodeSingleSelectOption>;
  /** @deprecated No longer used */
  requireConfirmation?: Maybe<Scalars['Boolean']>;
};

export type UserEpisodeSingleSelectOption = {
  __typename?: 'UserEpisodeSingleSelectOption';
  id: Scalars['String'];
  payload?: Maybe<UserEpisodeSingleSelectOptionPayload>;
  text: Scalars['String'];
  type?: Maybe<UserEpisodeSingleSelectOptionType>;
};

export type UserEpisodeSingleSelectOptionCoinToss = {
  __typename?: 'UserEpisodeSingleSelectOptionCoinToss';
  attribute?: Maybe<CharacterAttribute>;
};

export type UserEpisodeSingleSelectOptionPayload = UserEpisodeSingleSelectOptionCoinToss;

export enum UserEpisodeSingleSelectOptionType {
  CoinToss = 'COIN_TOSS',
  Default = 'DEFAULT',
  DiceRoll = 'DICE_ROLL'
}

export enum UserEpisodeStatBlockDirection {
  Column = 'COLUMN',
  Row = 'ROW'
}

export type UserEpisodeStatBlockField = {
  __typename?: 'UserEpisodeStatBlockField';
  name: Scalars['String'];
  value: Scalars['String'];
};

export type UserEpisodeStatBlockImage = {
  __typename?: 'UserEpisodeStatBlockImage';
  height: Scalars['Int'];
  /** @deprecated Use layout from statblock instead */
  size: UserEpisodeStatBlockImageSize;
  url: Scalars['String'];
  width: Scalars['Int'];
};

export enum UserEpisodeStatBlockImageSize {
  FullWidth = 'FULL_WIDTH',
  HalfWidth = 'HALF_WIDTH'
}

export enum UserEpisodeStatBlockLayout {
  Compact = 'COMPACT',
  FullWidth = 'FULL_WIDTH'
}

export type UserEpisodeStatBlockMessage = {
  __typename?: 'UserEpisodeStatBlockMessage';
  description: Scalars['String'];
  image?: Maybe<UserEpisodeStatBlockImage>;
  layout?: Maybe<UserEpisodeStatBlockLayout>;
  name: Scalars['String'];
  sections: Array<UserEpisodeStatBlockSection>;
  subtitle?: Maybe<Scalars['String']>;
};

export type UserEpisodeStatBlockSection = {
  __typename?: 'UserEpisodeStatBlockSection';
  /** @deprecated Use layout instead */
  direction: UserEpisodeStatBlockDirection;
  fields: Array<UserEpisodeStatBlockField>;
  layout: UserEpisodeStatBlockSectionLayout;
  title?: Maybe<Scalars['String']>;
};

export enum UserEpisodeStatBlockSectionLayout {
  /**
   * Displays a list of long descriptions.
   * Commonly used to describe abilities, traits, etc.
   */
  Description = 'DESCRIPTION',
  /**
   * Displays a list of items in a grid
   * Commonly used to show stats/attributes
   */
  GridList = 'GRID_LIST'
}

export type UserEpisodeStats = {
  __typename?: 'UserEpisodeStats';
  /** @deprecated No longer used to hydrate data */
  episode?: Maybe<Episode>;
  totalEpisodes: Scalars['Int'];
  /** @deprecated No longer used to hydrate data */
  userEpisode?: Maybe<UserEpisodeDetails>;
};

export type UserEpisodeStatsError = {
  __typename?: 'UserEpisodeStatsError';
  code: UserEpisodeStatsErrorCode;
  message: Scalars['String'];
};

export enum UserEpisodeStatsErrorCode {
  NotFound = 'NOT_FOUND',
  Unauthorized = 'UNAUTHORIZED'
}

export type UserEpisodeStatsResponse = {
  __typename?: 'UserEpisodeStatsResponse';
  data?: Maybe<UserEpisodeStats>;
  error?: Maybe<Array<UserEpisodeStatsError>>;
};

export enum UserEpisodeStatus {
  Ended = 'ENDED',
  InProgress = 'IN_PROGRESS'
}

export type UserEpisodeTextMessage = {
  __typename?: 'UserEpisodeTextMessage';
  character: GameCharacter;
  content: Scalars['String'];
  highlighted?: Maybe<Scalars['Boolean']>;
  partial?: Maybe<Scalars['Boolean']>;
};

export enum UserSessionError {
  Unauthorized = 'UNAUTHORIZED',
  Unknown = 'UNKNOWN'
}

export type UserSessionResponse = {
  __typename?: 'UserSessionResponse';
  error?: Maybe<Array<UserSessionError>>;
  jwt?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type VerifyReceiptError = {
  __typename?: 'VerifyReceiptError';
  code: VerifyReceiptErrorCode;
  message: Scalars['String'];
};

export enum VerifyReceiptErrorCode {
  InvalidReceipt = 'INVALID_RECEIPT',
  Unknown = 'UNKNOWN'
}

export type VerifyReceiptInput = {
  productId: Scalars['String'];
  receiptData: Scalars['String'];
};

export type VerifyReceiptResponse = {
  __typename?: 'VerifyReceiptResponse';
  credits: Scalars['Int'];
  error?: Maybe<Array<VerifyReceiptError>>;
};

export type WaitForTransactionReceiptQueryError = DisplayableError & {
  __typename?: 'WaitForTransactionReceiptQueryError';
  code: WaitForTransactionReceiptQueryErrorCode;
  message: Scalars['String'];
  source: Scalars['String'];
};

export enum WaitForTransactionReceiptQueryErrorCode {
  InvalidHash = 'INVALID_HASH',
  NotFound = 'NOT_FOUND',
  Unknown = 'UNKNOWN'
}

export type WaitForTransactionReceiptResponse = {
  __typename?: 'WaitForTransactionReceiptResponse';
  error?: Maybe<Array<WaitForTransactionReceiptQueryError>>;
  txReceipt?: Maybe<TransactionReceipt>;
};

export type World = {
  __typename?: 'World';
  /** Unique World ID */
  id: Scalars['ID'];
  /** URL of the World image */
  imageUrl: Scalars['String'];
  /** Name of the World */
  name: Scalars['String'];
  /** Count of all series that are a part of this world */
  seriesCount: Scalars['Int'];
};

export enum WorldQueryErrorCode {
  NotFound = 'NOT_FOUND',
  Unknown = 'UNKNOWN'
}

export type WorldResponse = {
  __typename?: 'WorldResponse';
  error?: Maybe<Array<WorldQueryErrorCode>>;
  world?: Maybe<World>;
};

export type WorldsInput = {
  filterCreatedByMe?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
};

export enum WorldsQueryErrorCode {
  Unknown = 'UNKNOWN'
}

export type WorldsResponse = {
  __typename?: 'WorldsResponse';
  error?: Maybe<Array<WorldsQueryErrorCode>>;
  worlds?: Maybe<Array<World>>;
};

export type DiscoverySectionSeriesFragment = { __typename?: 'DiscoverySectionSeries', onlyForKids: boolean, series: Array<{ __typename?: 'Series', id: string, title: string, media: { __typename?: 'SeriesMedia', main: string } }> };

export type DiscoverySectionFragment = { __typename?: 'DiscoverySection', id: string, name?: string | null | undefined, order: number, type: DiscoverySectionType, payload: { __typename?: 'DiscoverySectionGenres' } | { __typename?: 'DiscoverySectionHeroImage' } | { __typename?: 'DiscoverySectionRecentEpisodes' } | { __typename?: 'DiscoverySectionSeries', onlyForKids: boolean, series: Array<{ __typename?: 'Series', id: string, title: string, media: { __typename?: 'SeriesMedia', main: string } }> } };

export type EpisodeFragment = { __typename?: 'Episode', id: string, title: string, description: string, seriesId: string, ref?: string | null | undefined, hasDraft: boolean, hasNewVersion: boolean, media: { __typename?: 'EpisodeMedia', thumbnail: string, main: string } };

export type MaestroSeriesFragment = { __typename?: 'MaestroSeries', id: string, title?: string | null | undefined, description?: string | null | undefined, overview?: string | null | undefined, worldId?: string | null | undefined, genreId?: string | null | undefined, likesCount: number, playsCount: number, authorName?: string | null | undefined, media?: { __typename?: 'SeriesMedia', thumbnail: string, main: string } | null | undefined, world?: { __typename?: 'MaestroSeriesWorldDetails', id: string, title: string } | null | undefined, genre?: { __typename?: 'MaestroSeriesGenreDetails', id: string, title: string } | null | undefined };

export type SeriesFragment = { __typename?: 'Series', id: string, title: string, description: string, authorName?: string | null | undefined, genre: string, hasNewEpisode: boolean, releaseDateTime: string, playbackTimeInSeconds: number, status: SeriesStatus, episodeCount: number, createdByWorldCreator: boolean, media: { __typename?: 'SeriesMedia', thumbnail: string, main: string }, currentEpisode?: { __typename?: 'Episode', id: string, title: string, description: string, seriesId: string, ref?: string | null | undefined, hasDraft: boolean, hasNewVersion: boolean, media: { __typename?: 'EpisodeMedia', thumbnail: string, main: string } } | null | undefined };

export type SeriesShortFragment = { __typename?: 'Series', id: string, title: string, media: { __typename?: 'SeriesMedia', main: string } };

export type StudioEpisodeMinifiedFragment = { __typename?: 'StudioEpisode', id: string, title: string, description: string, media: { __typename?: 'EpisodeMedia', thumbnail: string, main: string } };

export type StudioEpisodeFragment = { __typename?: 'StudioEpisode', id: string, title: string, description: string, ref?: string | null | undefined, order?: number | null | undefined, playsCount: number, media: { __typename?: 'EpisodeMedia', thumbnail: string, main: string }, series: { __typename?: 'StudioEpisodeSeriesDetails', id: string, title: string, rpgConfig?: Record<string, unknown> | null | undefined, authorId: string }, publishedVersion?: { __typename?: 'PublishedEpisodeVersion', id: string, publishedAt: string, studioFlow: Record<string, unknown> } | null | undefined, draftVersion: { __typename?: 'DraftEpisodeVersion', id: string, updatedAt?: string | null | undefined, studioFlow: Record<string, unknown>, createdAt: string } };

export type AuthenticateWithProviderMutationVariables = Exact<{
  input: AuthenticateWithProviderInput;
}>;


export type AuthenticateWithProviderMutation = { __typename?: 'Mutation', authenticateWithProvider: { __typename?: 'AuthenticateWithProviderResponse', jwt?: string | null | undefined, isNewlyLinked?: boolean | null | undefined, error?: Array<AuthenticationError> | null | undefined, user?: { __typename?: 'User', id: string, email?: string | null | undefined, walletAddress?: string | null | undefined, authProviders: Array<AuthenticationProvider> } | null | undefined } };

export type CreateEpisodeMutationVariables = Exact<{
  input: CreateEpisodeInput;
}>;


export type CreateEpisodeMutation = { __typename?: 'Mutation', createEpisode: { __typename?: 'CreateEpisodeResponse', episode?: { __typename?: 'StudioEpisode', id: string, title: string, description: string, media: { __typename?: 'EpisodeMedia', thumbnail: string, main: string } } | null | undefined, error?: Array<{ __typename?: 'CreateEpisodeError', message: string, code: CreateEpisodeErrorCode }> | null | undefined } };

export type CreateSeriesMutationVariables = Exact<{
  input: CreateSeriesInput;
}>;


export type CreateSeriesMutation = { __typename?: 'Mutation', createSeries: { __typename?: 'CreateSeriesResponse', firstEpisodeId?: string | null | undefined, error?: Array<CreateSeriesErrorCode> | null | undefined, series?: { __typename?: 'Series', id: string, title: string, description: string, authorName?: string | null | undefined, genre: string, hasNewEpisode: boolean, releaseDateTime: string, playbackTimeInSeconds: number, status: SeriesStatus, episodeCount: number, createdByWorldCreator: boolean, media: { __typename?: 'SeriesMedia', thumbnail: string, main: string }, currentEpisode?: { __typename?: 'Episode', id: string, title: string, description: string, seriesId: string, ref?: string | null | undefined, hasDraft: boolean, hasNewVersion: boolean, media: { __typename?: 'EpisodeMedia', thumbnail: string, main: string } } | null | undefined } | null | undefined } };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', logout?: void | null | undefined };

export type CreateDiscoverySectionSeriesMutationVariables = Exact<{
  input: CreateDiscoverySectionInput;
}>;


export type CreateDiscoverySectionSeriesMutation = { __typename?: 'Mutation', discoverySection: { __typename?: 'DiscoverySectionMutations', createSectionSeries: { __typename?: 'CreateDiscoverySectionResponse', section?: { __typename?: 'DiscoverySection', id: string, name?: string | null | undefined, order: number, type: DiscoverySectionType, payload: { __typename?: 'DiscoverySectionGenres' } | { __typename?: 'DiscoverySectionHeroImage' } | { __typename?: 'DiscoverySectionRecentEpisodes' } | { __typename?: 'DiscoverySectionSeries', onlyForKids: boolean, series: Array<{ __typename?: 'Series', id: string, title: string, media: { __typename?: 'SeriesMedia', main: string } }> } } | null | undefined, error?: Array<{ __typename?: 'DiscoveryError', message: string, code: DiscoveryErrorCode }> | null | undefined } } };

export type UpdateDiscoverySectionSeriesMutationVariables = Exact<{
  input: UpdateDiscoverySectionInput;
}>;


export type UpdateDiscoverySectionSeriesMutation = { __typename?: 'Mutation', discoverySection: { __typename?: 'DiscoverySectionMutations', updateSectionSeries: { __typename?: 'UpdateDiscoverySectionResponse', error?: Array<{ __typename?: 'DiscoveryError', message: string, code: DiscoveryErrorCode }> | null | undefined } } };

export type DeleteDiscoverySectionSeriesMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteDiscoverySectionSeriesMutation = { __typename?: 'Mutation', discoverySection: { __typename?: 'DiscoverySectionMutations', deleteSection: { __typename?: 'DeleteDiscoverySectionResponse', error?: Array<{ __typename?: 'DiscoveryError', message: string, code: DiscoveryErrorCode }> | null | undefined } } };

export type PublishEpisodeMutationVariables = Exact<{
  input: PublishEpisodeInput;
}>;


export type PublishEpisodeMutation = { __typename?: 'Mutation', publishEpisode: { __typename?: 'PublishEpisodeResponse', error?: Array<{ __typename?: 'PublishEpisodeError', code: PublishEpisodeErrorCode, message: string }> | null | undefined } };

export type RealtimeTokenMutationVariables = Exact<{ [key: string]: never; }>;


export type RealtimeTokenMutation = { __typename?: 'Mutation', realtimeToken: { __typename?: 'RealtimeTokenResponse', token?: { __typename?: 'RealtimeToken', capability: string, expires: number, issued: number, token: string } | null | undefined, error?: Array<{ __typename?: 'RealtimeTokenError', message: string, code: RealtimeTokenErrorCode, source: string }> | null | undefined } };

export type UpdateEpisodeMutationVariables = Exact<{
  input: UpdateEpisodeInput;
}>;


export type UpdateEpisodeMutation = { __typename?: 'Mutation', updateEpisode: { __typename?: 'UpdateEpisodeResponse', episode?: { __typename?: 'UpdatedEpisodeDetails', id: string, title: string, description: string, media: { __typename?: 'EpisodeMedia', thumbnail: string, main: string } } | null | undefined, error?: Array<{ __typename?: 'UpdateEpisodeError', code: UpdateEpisodeErrorCode, message: string }> | null | undefined } };

export type UpdateRpgConfigMutationVariables = Exact<{
  input: UpdateSeriesRpgConfigInput;
}>;


export type UpdateRpgConfigMutation = { __typename?: 'Mutation', updateSeriesRpgConfig: { __typename?: 'RpgConfigResponse', error?: Array<{ __typename?: 'SeriesQueryError', code: SeriesErrorCode, message: string }> | null | undefined } };

export type UpdateSeriesMutationVariables = Exact<{
  input: UpdateSeriesInput;
}>;


export type UpdateSeriesMutation = { __typename?: 'Mutation', updateSeries: { __typename?: 'UpdateSeriesResponse', series?: { __typename?: 'Series', id: string, title: string, description: string, authorName?: string | null | undefined, genre: string, hasNewEpisode: boolean, releaseDateTime: string, playbackTimeInSeconds: number, status: SeriesStatus, episodeCount: number, createdByWorldCreator: boolean, media: { __typename?: 'SeriesMedia', thumbnail: string, main: string }, currentEpisode?: { __typename?: 'Episode', id: string, title: string, description: string, seriesId: string, ref?: string | null | undefined, hasDraft: boolean, hasNewVersion: boolean, media: { __typename?: 'EpisodeMedia', thumbnail: string, main: string } } | null | undefined } | null | undefined, error?: Array<{ __typename?: 'UpdateSeriesError', code: UpdateSeriesErrorCode, message: string }> | null | undefined } };

export type CreateSeriesSatelliteDataQueryVariables = Exact<{ [key: string]: never; }>;


export type CreateSeriesSatelliteDataQuery = { __typename?: 'Query', createSeriesSatelliteData: { __typename?: 'CreateSeriesSatelliteDataResponse', genres?: Array<{ __typename?: 'CreateSeriesSatelliteDataOption', id: string, label: string }> | null | undefined, worlds?: Array<{ __typename?: 'CreateSeriesSatelliteDataOption', id: string, label: string }> | null | undefined } };

export type MaestroEpisodesQueryVariables = Exact<{
  input: MaestroEpisodesQueryInput;
}>;


export type MaestroEpisodesQuery = { __typename?: 'Query', maestroEpisodes: { __typename?: 'MaestroEpisodesResponse', episodes?: Array<{ __typename?: 'StudioEpisode', id: string, title: string, description: string, ref?: string | null | undefined, order?: number | null | undefined, playsCount: number, media: { __typename?: 'EpisodeMedia', thumbnail: string, main: string }, series: { __typename?: 'StudioEpisodeSeriesDetails', id: string, title: string, rpgConfig?: Record<string, unknown> | null | undefined, authorId: string }, publishedVersion?: { __typename?: 'PublishedEpisodeVersion', id: string, publishedAt: string, studioFlow: Record<string, unknown> } | null | undefined, draftVersion: { __typename?: 'DraftEpisodeVersion', id: string, updatedAt?: string | null | undefined, studioFlow: Record<string, unknown>, createdAt: string } }> | null | undefined, metadata?: { __typename?: 'PaginatedResultMetadata', hasMore: boolean, nextCursor?: { __typename?: 'PageCursor', offset: number, limit: number } | null | undefined } | null | undefined, error?: Array<{ __typename?: 'MaestroEpisodesQueryError', message: string, code: MaestroEpisodesErrorCode }> | null | undefined } };

export type MaestroSeriesQueryVariables = Exact<{
  input: MaestroSeriesQueryInput;
}>;


export type MaestroSeriesQuery = { __typename?: 'Query', maestroSeries: { __typename?: 'MaestroSeriesResponse', series?: { __typename?: 'MaestroSeries', id: string, title?: string | null | undefined, description?: string | null | undefined, overview?: string | null | undefined, worldId?: string | null | undefined, genreId?: string | null | undefined, likesCount: number, playsCount: number, authorName?: string | null | undefined, media?: { __typename?: 'SeriesMedia', thumbnail: string, main: string } | null | undefined, world?: { __typename?: 'MaestroSeriesWorldDetails', id: string, title: string } | null | undefined, genre?: { __typename?: 'MaestroSeriesGenreDetails', id: string, title: string } | null | undefined } | null | undefined, error?: Array<{ __typename?: 'MaestroSeriesQueryError', message: string, code: MaestroSeriesErrorCode }> | null | undefined } };

export type MaestroDiscoveryQueryVariables = Exact<{
  input?: InputMaybe<DiscoveryInput>;
}>;


export type MaestroDiscoveryQuery = { __typename?: 'Query', series: { __typename?: 'SeriesResponse', series: Array<{ __typename?: 'Series', id: string, title: string, media: { __typename?: 'SeriesMedia', main: string } }> }, discovery: { __typename?: 'DiscoveryResponse', sections?: Array<{ __typename?: 'DiscoverySection', id: string, name?: string | null | undefined, order: number, type: DiscoverySectionType, payload: { __typename?: 'DiscoverySectionGenres' } | { __typename?: 'DiscoverySectionHeroImage' } | { __typename?: 'DiscoverySectionRecentEpisodes' } | { __typename?: 'DiscoverySectionSeries', onlyForKids: boolean, series: Array<{ __typename?: 'Series', id: string, title: string, media: { __typename?: 'SeriesMedia', main: string } }> } }> | null | undefined, error?: Array<{ __typename?: 'DiscoveryError', message: string, code: DiscoveryErrorCode }> | null | undefined } };

export type PrePublishEpisodeVersionValidationQueryVariables = Exact<{
  input: PrePublishEpisodeVersionValidationInput;
}>;


export type PrePublishEpisodeVersionValidationQuery = { __typename?: 'Query', prePublishEpisodeVersionValidation: { __typename?: 'PrePublishEpisodeVersionValidationResponse', status?: PrePublishEpisodeVersionValidationStatus | null | undefined, episodeValidations?: Array<{ __typename?: 'PrePublishEpisodeVersionValidation', field: string, message: string }> | null | undefined, seriesValidations?: Array<{ __typename?: 'PrePublishEpisodeVersionValidation', field: string, message: string }> | null | undefined, error?: Array<{ __typename?: 'PrePublishEpisodeVersionValidationError', message: string, code: PrePublishEpisodeVersionValidationErrorCode }> | null | undefined } };

export type StudioEpisodeQueryVariables = Exact<{
  input: StudioEpisodeQueryInput;
}>;


export type StudioEpisodeQuery = { __typename?: 'Query', studioEpisode: { __typename?: 'StudioEpisodeQueryResponse', data?: { __typename?: 'StudioEpisode', id: string, title: string, description: string, ref?: string | null | undefined, order?: number | null | undefined, playsCount: number, media: { __typename?: 'EpisodeMedia', thumbnail: string, main: string }, series: { __typename?: 'StudioEpisodeSeriesDetails', id: string, title: string, rpgConfig?: Record<string, unknown> | null | undefined, authorId: string }, publishedVersion?: { __typename?: 'PublishedEpisodeVersion', id: string, publishedAt: string, studioFlow: Record<string, unknown> } | null | undefined, draftVersion: { __typename?: 'DraftEpisodeVersion', id: string, updatedAt?: string | null | undefined, studioFlow: Record<string, unknown>, createdAt: string } } | null | undefined, error?: Array<{ __typename?: 'StudioEpisodeQueryError', message: string, code: StudioEpisodeQueryErrorCode }> | null | undefined } };

export type EpisodeStatusQueryVariables = Exact<{
  input: StudioEpisodeQueryInput;
}>;


export type EpisodeStatusQuery = { __typename?: 'Query', studioEpisode: { __typename?: 'StudioEpisodeQueryResponse', data?: { __typename?: 'StudioEpisode', id: string, order?: number | null | undefined, publishedVersion?: { __typename?: 'PublishedEpisodeVersion', publishedAt: string } | null | undefined, draftVersion: { __typename?: 'DraftEpisodeVersion', updatedAt?: string | null | undefined } } | null | undefined, error?: Array<{ __typename?: 'StudioEpisodeQueryError', message: string, code: StudioEpisodeQueryErrorCode }> | null | undefined } };

export const SeriesShortFragmentDoc = `
    fragment SeriesShort on Series {
  id
  title
  media {
    main
  }
}
    `;
export const DiscoverySectionSeriesFragmentDoc = `
    fragment DiscoverySectionSeries on DiscoverySectionSeries {
  series {
    ...SeriesShort
  }
  onlyForKids
}
    `;
export const DiscoverySectionFragmentDoc = `
    fragment DiscoverySection on DiscoverySection {
  id
  name
  order
  type
  payload {
    ... on DiscoverySectionSeries {
      ...DiscoverySectionSeries
    }
  }
}
    `;
export const MaestroSeriesFragmentDoc = `
    fragment MaestroSeries on MaestroSeries {
  id
  title
  description
  overview
  media {
    thumbnail
    main
  }
  worldId
  genreId
  world {
    id
    title
  }
  genre {
    id
    title
  }
  likesCount
  playsCount
  authorName
}
    `;
export const EpisodeFragmentDoc = `
    fragment Episode on Episode {
  id
  title
  description
  media {
    thumbnail
    main
  }
  seriesId
  ref
  hasDraft
  hasNewVersion
}
    `;
export const SeriesFragmentDoc = `
    fragment Series on Series {
  id
  title
  description
  authorName
  genre
  hasNewEpisode
  releaseDateTime
  media {
    thumbnail
    main
  }
  playbackTimeInSeconds
  status
  episodeCount
  currentEpisode {
    ...Episode
  }
  createdByWorldCreator
}
    `;
export const StudioEpisodeMinifiedFragmentDoc = `
    fragment StudioEpisodeMinified on StudioEpisode {
  id
  title
  description
  media {
    thumbnail
    main
  }
}
    `;
export const StudioEpisodeFragmentDoc = `
    fragment StudioEpisode on StudioEpisode {
  id
  title
  description
  media {
    thumbnail
    main
  }
  ref
  series {
    id
    title
    rpgConfig
    authorId
  }
  publishedVersion {
    id
    publishedAt
    studioFlow
  }
  draftVersion {
    id
    updatedAt
    studioFlow
    createdAt
  }
  order
  playsCount
}
    `;
export const AuthenticateWithProviderDocument = `
    mutation authenticateWithProvider($input: AuthenticateWithProviderInput!) {
  authenticateWithProvider(input: $input) {
    jwt
    isNewlyLinked
    user {
      id
      email
      walletAddress
      authProviders
    }
    error
  }
}
    `;
export const useAuthenticateWithProviderMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AuthenticateWithProviderMutation, TError, AuthenticateWithProviderMutationVariables, TContext>) =>
    useMutation<AuthenticateWithProviderMutation, TError, AuthenticateWithProviderMutationVariables, TContext>(
      ['authenticateWithProvider'],
      (variables?: AuthenticateWithProviderMutationVariables) => fetcher<AuthenticateWithProviderMutation, AuthenticateWithProviderMutationVariables>(AuthenticateWithProviderDocument, variables)(),
      options
    );
export const CreateEpisodeDocument = `
    mutation createEpisode($input: CreateEpisodeInput!) {
  createEpisode(input: $input) {
    episode {
      ...StudioEpisodeMinified
    }
    error {
      message
      code
    }
  }
}
    ${StudioEpisodeMinifiedFragmentDoc}`;
export const useCreateEpisodeMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateEpisodeMutation, TError, CreateEpisodeMutationVariables, TContext>) =>
    useMutation<CreateEpisodeMutation, TError, CreateEpisodeMutationVariables, TContext>(
      ['createEpisode'],
      (variables?: CreateEpisodeMutationVariables) => fetcher<CreateEpisodeMutation, CreateEpisodeMutationVariables>(CreateEpisodeDocument, variables)(),
      options
    );
export const CreateSeriesDocument = `
    mutation createSeries($input: CreateSeriesInput!) {
  createSeries(input: $input) {
    series {
      ...Series
    }
    firstEpisodeId
    error
  }
}
    ${SeriesFragmentDoc}
${EpisodeFragmentDoc}`;
export const useCreateSeriesMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateSeriesMutation, TError, CreateSeriesMutationVariables, TContext>) =>
    useMutation<CreateSeriesMutation, TError, CreateSeriesMutationVariables, TContext>(
      ['createSeries'],
      (variables?: CreateSeriesMutationVariables) => fetcher<CreateSeriesMutation, CreateSeriesMutationVariables>(CreateSeriesDocument, variables)(),
      options
    );
export const LogoutDocument = `
    mutation logout {
  logout
}
    `;
export const useLogoutMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<LogoutMutation, TError, LogoutMutationVariables, TContext>) =>
    useMutation<LogoutMutation, TError, LogoutMutationVariables, TContext>(
      ['logout'],
      (variables?: LogoutMutationVariables) => fetcher<LogoutMutation, LogoutMutationVariables>(LogoutDocument, variables)(),
      options
    );
export const CreateDiscoverySectionSeriesDocument = `
    mutation createDiscoverySectionSeries($input: CreateDiscoverySectionInput!) {
  discoverySection {
    createSectionSeries(input: $input) {
      section {
        ...DiscoverySection
      }
      error {
        message
        code
      }
    }
  }
}
    ${DiscoverySectionFragmentDoc}
${DiscoverySectionSeriesFragmentDoc}
${SeriesShortFragmentDoc}`;
export const useCreateDiscoverySectionSeriesMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateDiscoverySectionSeriesMutation, TError, CreateDiscoverySectionSeriesMutationVariables, TContext>) =>
    useMutation<CreateDiscoverySectionSeriesMutation, TError, CreateDiscoverySectionSeriesMutationVariables, TContext>(
      ['createDiscoverySectionSeries'],
      (variables?: CreateDiscoverySectionSeriesMutationVariables) => fetcher<CreateDiscoverySectionSeriesMutation, CreateDiscoverySectionSeriesMutationVariables>(CreateDiscoverySectionSeriesDocument, variables)(),
      options
    );
export const UpdateDiscoverySectionSeriesDocument = `
    mutation updateDiscoverySectionSeries($input: UpdateDiscoverySectionInput!) {
  discoverySection {
    updateSectionSeries(input: $input) {
      error {
        message
        code
      }
    }
  }
}
    `;
export const useUpdateDiscoverySectionSeriesMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateDiscoverySectionSeriesMutation, TError, UpdateDiscoverySectionSeriesMutationVariables, TContext>) =>
    useMutation<UpdateDiscoverySectionSeriesMutation, TError, UpdateDiscoverySectionSeriesMutationVariables, TContext>(
      ['updateDiscoverySectionSeries'],
      (variables?: UpdateDiscoverySectionSeriesMutationVariables) => fetcher<UpdateDiscoverySectionSeriesMutation, UpdateDiscoverySectionSeriesMutationVariables>(UpdateDiscoverySectionSeriesDocument, variables)(),
      options
    );
export const DeleteDiscoverySectionSeriesDocument = `
    mutation deleteDiscoverySectionSeries($id: ID!) {
  discoverySection {
    deleteSection(id: $id) {
      error {
        message
        code
      }
    }
  }
}
    `;
export const useDeleteDiscoverySectionSeriesMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteDiscoverySectionSeriesMutation, TError, DeleteDiscoverySectionSeriesMutationVariables, TContext>) =>
    useMutation<DeleteDiscoverySectionSeriesMutation, TError, DeleteDiscoverySectionSeriesMutationVariables, TContext>(
      ['deleteDiscoverySectionSeries'],
      (variables?: DeleteDiscoverySectionSeriesMutationVariables) => fetcher<DeleteDiscoverySectionSeriesMutation, DeleteDiscoverySectionSeriesMutationVariables>(DeleteDiscoverySectionSeriesDocument, variables)(),
      options
    );
export const PublishEpisodeDocument = `
    mutation publishEpisode($input: PublishEpisodeInput!) {
  publishEpisode(input: $input) {
    error {
      code
      message
    }
  }
}
    `;
export const usePublishEpisodeMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<PublishEpisodeMutation, TError, PublishEpisodeMutationVariables, TContext>) =>
    useMutation<PublishEpisodeMutation, TError, PublishEpisodeMutationVariables, TContext>(
      ['publishEpisode'],
      (variables?: PublishEpisodeMutationVariables) => fetcher<PublishEpisodeMutation, PublishEpisodeMutationVariables>(PublishEpisodeDocument, variables)(),
      options
    );
export const RealtimeTokenDocument = `
    mutation realtimeToken {
  realtimeToken {
    token {
      capability
      expires
      issued
      token
    }
    error {
      message
      code
      source
    }
  }
}
    `;
export const useRealtimeTokenMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<RealtimeTokenMutation, TError, RealtimeTokenMutationVariables, TContext>) =>
    useMutation<RealtimeTokenMutation, TError, RealtimeTokenMutationVariables, TContext>(
      ['realtimeToken'],
      (variables?: RealtimeTokenMutationVariables) => fetcher<RealtimeTokenMutation, RealtimeTokenMutationVariables>(RealtimeTokenDocument, variables)(),
      options
    );
export const UpdateEpisodeDocument = `
    mutation updateEpisode($input: UpdateEpisodeInput!) {
  updateEpisode(input: $input) {
    episode {
      id
      title
      description
      media {
        thumbnail
        main
      }
    }
    error {
      code
      message
    }
  }
}
    `;
export const useUpdateEpisodeMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateEpisodeMutation, TError, UpdateEpisodeMutationVariables, TContext>) =>
    useMutation<UpdateEpisodeMutation, TError, UpdateEpisodeMutationVariables, TContext>(
      ['updateEpisode'],
      (variables?: UpdateEpisodeMutationVariables) => fetcher<UpdateEpisodeMutation, UpdateEpisodeMutationVariables>(UpdateEpisodeDocument, variables)(),
      options
    );
export const UpdateRpgConfigDocument = `
    mutation updateRpgConfig($input: UpdateSeriesRpgConfigInput!) {
  updateSeriesRpgConfig(input: $input) {
    error {
      code
      message
    }
  }
}
    `;
export const useUpdateRpgConfigMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateRpgConfigMutation, TError, UpdateRpgConfigMutationVariables, TContext>) =>
    useMutation<UpdateRpgConfigMutation, TError, UpdateRpgConfigMutationVariables, TContext>(
      ['updateRpgConfig'],
      (variables?: UpdateRpgConfigMutationVariables) => fetcher<UpdateRpgConfigMutation, UpdateRpgConfigMutationVariables>(UpdateRpgConfigDocument, variables)(),
      options
    );
export const UpdateSeriesDocument = `
    mutation updateSeries($input: UpdateSeriesInput!) {
  updateSeries(input: $input) {
    series {
      ...Series
    }
    error {
      code
      message
    }
  }
}
    ${SeriesFragmentDoc}
${EpisodeFragmentDoc}`;
export const useUpdateSeriesMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateSeriesMutation, TError, UpdateSeriesMutationVariables, TContext>) =>
    useMutation<UpdateSeriesMutation, TError, UpdateSeriesMutationVariables, TContext>(
      ['updateSeries'],
      (variables?: UpdateSeriesMutationVariables) => fetcher<UpdateSeriesMutation, UpdateSeriesMutationVariables>(UpdateSeriesDocument, variables)(),
      options
    );
export const CreateSeriesSatelliteDataDocument = `
    query createSeriesSatelliteData {
  createSeriesSatelliteData {
    genres {
      id
      label
    }
    worlds {
      id
      label
    }
  }
}
    `;
export const useCreateSeriesSatelliteDataQuery = <
      TData = CreateSeriesSatelliteDataQuery,
      TError = unknown
    >(
      variables?: CreateSeriesSatelliteDataQueryVariables,
      options?: UseQueryOptions<CreateSeriesSatelliteDataQuery, TError, TData>
    ) =>
    useQuery<CreateSeriesSatelliteDataQuery, TError, TData>(
      variables === undefined ? ['createSeriesSatelliteData'] : ['createSeriesSatelliteData', variables],
      fetcher<CreateSeriesSatelliteDataQuery, CreateSeriesSatelliteDataQueryVariables>(CreateSeriesSatelliteDataDocument, variables),
      options
    );
export const useInfiniteCreateSeriesSatelliteDataQuery = <
      TData = CreateSeriesSatelliteDataQuery,
      TError = unknown
    >(
      variables?: CreateSeriesSatelliteDataQueryVariables,
      options?: UseInfiniteQueryOptions<CreateSeriesSatelliteDataQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<CreateSeriesSatelliteDataQuery, TError, TData>(
      variables === undefined ? ['createSeriesSatelliteData.infinite'] : ['createSeriesSatelliteData.infinite', variables],
      (metaData) => fetcher<CreateSeriesSatelliteDataQuery, CreateSeriesSatelliteDataQueryVariables>(CreateSeriesSatelliteDataDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const MaestroEpisodesDocument = `
    query maestroEpisodes($input: MaestroEpisodesQueryInput!) {
  maestroEpisodes(input: $input) {
    episodes {
      ...StudioEpisode
    }
    metadata {
      hasMore
      nextCursor {
        offset
        limit
      }
    }
    error {
      message
      code
    }
  }
}
    ${StudioEpisodeFragmentDoc}`;
export const useMaestroEpisodesQuery = <
      TData = MaestroEpisodesQuery,
      TError = unknown
    >(
      variables: MaestroEpisodesQueryVariables,
      options?: UseQueryOptions<MaestroEpisodesQuery, TError, TData>
    ) =>
    useQuery<MaestroEpisodesQuery, TError, TData>(
      ['maestroEpisodes', variables],
      fetcher<MaestroEpisodesQuery, MaestroEpisodesQueryVariables>(MaestroEpisodesDocument, variables),
      options
    );
export const useInfiniteMaestroEpisodesQuery = <
      TData = MaestroEpisodesQuery,
      TError = unknown
    >(
      variables: MaestroEpisodesQueryVariables,
      options?: UseInfiniteQueryOptions<MaestroEpisodesQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<MaestroEpisodesQuery, TError, TData>(
      ['maestroEpisodes.infinite', variables],
      (metaData) => fetcher<MaestroEpisodesQuery, MaestroEpisodesQueryVariables>(MaestroEpisodesDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const MaestroSeriesDocument = `
    query maestroSeries($input: MaestroSeriesQueryInput!) {
  maestroSeries(input: $input) {
    series {
      ...MaestroSeries
    }
    error {
      message
      code
    }
  }
}
    ${MaestroSeriesFragmentDoc}`;
export const useMaestroSeriesQuery = <
      TData = MaestroSeriesQuery,
      TError = unknown
    >(
      variables: MaestroSeriesQueryVariables,
      options?: UseQueryOptions<MaestroSeriesQuery, TError, TData>
    ) =>
    useQuery<MaestroSeriesQuery, TError, TData>(
      ['maestroSeries', variables],
      fetcher<MaestroSeriesQuery, MaestroSeriesQueryVariables>(MaestroSeriesDocument, variables),
      options
    );
export const useInfiniteMaestroSeriesQuery = <
      TData = MaestroSeriesQuery,
      TError = unknown
    >(
      variables: MaestroSeriesQueryVariables,
      options?: UseInfiniteQueryOptions<MaestroSeriesQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<MaestroSeriesQuery, TError, TData>(
      ['maestroSeries.infinite', variables],
      (metaData) => fetcher<MaestroSeriesQuery, MaestroSeriesQueryVariables>(MaestroSeriesDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const MaestroDiscoveryDocument = `
    query maestroDiscovery($input: DiscoveryInput) {
  series {
    series {
      ...SeriesShort
    }
  }
  discovery(input: $input) {
    sections {
      ...DiscoverySection
    }
    error {
      message
      code
    }
  }
}
    ${SeriesShortFragmentDoc}
${DiscoverySectionFragmentDoc}
${DiscoverySectionSeriesFragmentDoc}`;
export const useMaestroDiscoveryQuery = <
      TData = MaestroDiscoveryQuery,
      TError = unknown
    >(
      variables?: MaestroDiscoveryQueryVariables,
      options?: UseQueryOptions<MaestroDiscoveryQuery, TError, TData>
    ) =>
    useQuery<MaestroDiscoveryQuery, TError, TData>(
      variables === undefined ? ['maestroDiscovery'] : ['maestroDiscovery', variables],
      fetcher<MaestroDiscoveryQuery, MaestroDiscoveryQueryVariables>(MaestroDiscoveryDocument, variables),
      options
    );
export const useInfiniteMaestroDiscoveryQuery = <
      TData = MaestroDiscoveryQuery,
      TError = unknown
    >(
      variables?: MaestroDiscoveryQueryVariables,
      options?: UseInfiniteQueryOptions<MaestroDiscoveryQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<MaestroDiscoveryQuery, TError, TData>(
      variables === undefined ? ['maestroDiscovery.infinite'] : ['maestroDiscovery.infinite', variables],
      (metaData) => fetcher<MaestroDiscoveryQuery, MaestroDiscoveryQueryVariables>(MaestroDiscoveryDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const PrePublishEpisodeVersionValidationDocument = `
    query prePublishEpisodeVersionValidation($input: PrePublishEpisodeVersionValidationInput!) {
  prePublishEpisodeVersionValidation(input: $input) {
    status
    episodeValidations {
      field
      message
    }
    seriesValidations {
      field
      message
    }
    error {
      message
      code
    }
  }
}
    `;
export const usePrePublishEpisodeVersionValidationQuery = <
      TData = PrePublishEpisodeVersionValidationQuery,
      TError = unknown
    >(
      variables: PrePublishEpisodeVersionValidationQueryVariables,
      options?: UseQueryOptions<PrePublishEpisodeVersionValidationQuery, TError, TData>
    ) =>
    useQuery<PrePublishEpisodeVersionValidationQuery, TError, TData>(
      ['prePublishEpisodeVersionValidation', variables],
      fetcher<PrePublishEpisodeVersionValidationQuery, PrePublishEpisodeVersionValidationQueryVariables>(PrePublishEpisodeVersionValidationDocument, variables),
      options
    );
export const useInfinitePrePublishEpisodeVersionValidationQuery = <
      TData = PrePublishEpisodeVersionValidationQuery,
      TError = unknown
    >(
      variables: PrePublishEpisodeVersionValidationQueryVariables,
      options?: UseInfiniteQueryOptions<PrePublishEpisodeVersionValidationQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<PrePublishEpisodeVersionValidationQuery, TError, TData>(
      ['prePublishEpisodeVersionValidation.infinite', variables],
      (metaData) => fetcher<PrePublishEpisodeVersionValidationQuery, PrePublishEpisodeVersionValidationQueryVariables>(PrePublishEpisodeVersionValidationDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const StudioEpisodeDocument = `
    query studioEpisode($input: StudioEpisodeQueryInput!) {
  studioEpisode(input: $input) {
    data {
      ...StudioEpisode
    }
    error {
      message
      code
    }
  }
}
    ${StudioEpisodeFragmentDoc}`;
export const useStudioEpisodeQuery = <
      TData = StudioEpisodeQuery,
      TError = unknown
    >(
      variables: StudioEpisodeQueryVariables,
      options?: UseQueryOptions<StudioEpisodeQuery, TError, TData>
    ) =>
    useQuery<StudioEpisodeQuery, TError, TData>(
      ['studioEpisode', variables],
      fetcher<StudioEpisodeQuery, StudioEpisodeQueryVariables>(StudioEpisodeDocument, variables),
      options
    );
export const useInfiniteStudioEpisodeQuery = <
      TData = StudioEpisodeQuery,
      TError = unknown
    >(
      variables: StudioEpisodeQueryVariables,
      options?: UseInfiniteQueryOptions<StudioEpisodeQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<StudioEpisodeQuery, TError, TData>(
      ['studioEpisode.infinite', variables],
      (metaData) => fetcher<StudioEpisodeQuery, StudioEpisodeQueryVariables>(StudioEpisodeDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const EpisodeStatusDocument = `
    query episodeStatus($input: StudioEpisodeQueryInput!) {
  studioEpisode(input: $input) {
    data {
      id
      publishedVersion {
        publishedAt
      }
      draftVersion {
        updatedAt
      }
      order
    }
    error {
      message
      code
    }
  }
}
    `;
export const useEpisodeStatusQuery = <
      TData = EpisodeStatusQuery,
      TError = unknown
    >(
      variables: EpisodeStatusQueryVariables,
      options?: UseQueryOptions<EpisodeStatusQuery, TError, TData>
    ) =>
    useQuery<EpisodeStatusQuery, TError, TData>(
      ['episodeStatus', variables],
      fetcher<EpisodeStatusQuery, EpisodeStatusQueryVariables>(EpisodeStatusDocument, variables),
      options
    );
export const useInfiniteEpisodeStatusQuery = <
      TData = EpisodeStatusQuery,
      TError = unknown
    >(
      variables: EpisodeStatusQueryVariables,
      options?: UseInfiniteQueryOptions<EpisodeStatusQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<EpisodeStatusQuery, TError, TData>(
      ['episodeStatus.infinite', variables],
      (metaData) => fetcher<EpisodeStatusQuery, EpisodeStatusQueryVariables>(EpisodeStatusDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};
