import { ImageNodeData } from '@common/studio-types';
import { Field, RadioButtons, Textarea } from '@maestro/components';
import React from 'react';
import { Hint } from '../Hint';
import { uniquenessOptions } from './shared';

type Props = {
  onStudioNodeDataChange: (studioNode: ImageNodeData) => void;
  studioNodeData: ImageNodeData;
};

export const GenerateImageWithAiFields: React.FC<Props> = (props) => {
  return (
    <>
      <Field label="Negative prompt">
        <Textarea
          placeholder="Tell AI what to avoid when generating this node"
          value={props.studioNodeData.generateImage?.negativePrompt ?? ''}
          onChange={(e) =>
            props.onStudioNodeDataChange({
              ...props.studioNodeData!,
              generateImage: {
                ...(props.studioNodeData.generateImage ?? {}),
                negativePrompt: e.target.value,
              },
            })
          }
        />
      </Field>
      {!!props.studioNodeData.imageUrl && (
        <Field label="Result Uniqueness">
          <Hint>
            Indicate how unique you want the result to be compared to the node
            image.
          </Hint>
          <RadioButtons
            options={uniquenessOptions}
            value={props.studioNodeData.generateImage?.uniqueness}
            onChange={(selection) =>
              props.onStudioNodeDataChange({
                ...props.studioNodeData!,
                generateImage: {
                  ...(props.studioNodeData.generateImage ?? {}),
                  uniqueness: selection,
                },
              })
            }
          />
        </Field>
      )}
    </>
  );
};
